import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { PaymentType } from '../../models/enums/payment-type.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as uuid from 'uuid';
import { MatRadioChange } from '@angular/material/radio';
import { AdditionAndSubtraction } from '../../models/addition-and-subtraction.model';
import { Observable, Subject } from 'rxjs';
import { Tax } from '../../models/tax.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hmt-add-addition-and-subtraction',
  templateUrl: './add-addition-and-subtraction.component.html',
  styleUrls: ['./add-addition-and-subtraction.component.scss']
})
export class AddAdditionAndSubtractionComponent implements OnInit, OnDestroy { // same component use for discount and tax and line item fees

  @Output() addAdditionAndSubtraction: EventEmitter<AdditionAndSubtraction> = new EventEmitter();
  paymentType: PaymentType = PaymentType.AMOUNT;
  discountForm: FormGroup;
  taxForm: FormGroup;
  paymentTypes = PaymentType;
  taxNames: string[];
  taxes: Tax[] = [];
  unsubscribe: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<AddAdditionAndSubtractionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      paymentType: PaymentType,
      additionAndSubtraction: AdditionAndSubtraction;
      type: string;
      taxes: Observable<Tax[]>;
      name: string;
    }
  ) {

  }

  ngOnInit(): void {
    this.data.taxes
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(taxes => {
        this.taxNames = taxes.map(tax => tax.name.toUpperCase());
        this.taxes = taxes;
      });

    this.discountForm = new FormGroup({
      description: new FormControl(''),
      value: new FormControl('', [Validators.required, this.twoDecimalPlacesValidator()]),
    });

    this.taxForm = new FormGroup({
      taxName: new FormControl(''),
      percentage: new FormControl('', []),
    });
    this.paymentType = this.data.paymentType;

    if (this.data.additionAndSubtraction != null) {
      this.paymentType = this.data.additionAndSubtraction.paymentType;

      if (this.data.type === 'tax') {
        const index = this.taxes?.findIndex(tax => tax.name === this.data.additionAndSubtraction.description);
        this.taxForm.controls.taxName.setValue(this.data.additionAndSubtraction.description);
        this.taxForm.controls.percentage.setValue(this.taxes[index].percentage);
      } else {
        this.discountForm.controls.description.setValue(this.data.additionAndSubtraction.description);
        this.discountForm.controls.value.setValue(this.data.additionAndSubtraction.value);
      }
    }
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    let obj: AdditionAndSubtraction;
    if (this.data.type === 'tax') {
      obj = {
        id: this.data.additionAndSubtraction == null ? uuid.v4() : this.data.additionAndSubtraction.id,
        value: +this.taxForm.get('percentage').value,
        description: this.taxForm.get('taxName').value,
        paymentType: PaymentType.PERCENTAGE
      };
    } else {
      if(!this.discountForm.get('value').hasError('invalidDecimalPlaces') && !this.discountForm.get('value').hasError('invalidNumber')){
        obj = {
          id: this.data.additionAndSubtraction == null ? uuid.v4() : this.data.additionAndSubtraction.id,
          value: +this.discountForm.get('value').value,
          description: this.discountForm.get('description').value,
          paymentType: this.paymentType
        };
      }
    }
    if(obj.value){
      this.addAdditionAndSubtraction.emit(obj);
      this.close();
    }
    
  }

  onchange($event: MatRadioChange) {
    if ($event.value === 1) {
      this.paymentType = PaymentType.AMOUNT;
    } else {
      this.paymentType = PaymentType.PERCENTAGE;
    }
  }

  onchangeTax(taxName: string) {
    const index = this.taxes.findIndex(tax => tax.name === taxName);
    this.taxForm.controls.percentage.setValue(this.taxes[index].percentage);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  twoDecimalPlacesValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (value === null || value === undefined || value === '') {
        return null; // If empty, validation handled by required validator
      }
      if (isNaN(value)) {
        return { 'invalidNumber': true };
      }
      if (value<0) {
        return { 'invalidNumber': true };
      }
      const parts = value.toString().split('.');
      if (parts.length > 1 && parts[1].length > 2) {
        return { 'invalidDecimalPlaces': true };
      }
      return null;
    };
  }
}
