import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InvoiceCurrency} from '../../models/invoice-currency.model';
import {Observable} from 'rxjs';
import {RateCard} from '@shared/models/rate-card.model';
import {Currency} from '../../../../shared/models/currency.model';
import {Invoice} from '@shared/models/invoice/invoice.model';

@Component({
  selector: 'hmt-change-currency',
  templateUrl: './change-currency.component.html',
  styleUrls: ['./change-currency.component.scss']
})
export class ChangeCurrencyComponent implements OnInit {
  @Output() changeCurrency: EventEmitter<InvoiceCurrency> = new EventEmitter<InvoiceCurrency>();
  form: FormGroup;
  currencies: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ChangeCurrencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      invoice: Invoice,
      currencies: Observable<Currency[]>;
    }
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      currency: new FormControl(this.data.invoice.currency.currentCurrency),
      rate: new FormControl({value: this.data.invoice.currency.currencyRate, disabled: true})
    });
    this.currencies = [this.data.invoice.defaultOrgCurrencyCode, this.data.invoice.currencyCode];
  }

  close() {
    this.dialogRef.close();
  }

  onChangeCurrency(value: string) {
    if (value === this.data.invoice.defaultOrgCurrencyCode){
      this.form.controls.rate.setValue(1 / this.data.invoice.exchangeRate);
    }
    else {
      this.form.controls.rate.setValue(1);
    }
  }

  onApplyCurrency() {
    const currency = {
      defaultCurrency: this.data.invoice.currency.defaultCurrency,
      currentCurrency: this.form.get('currency').value,
      currencyRate: this.form.get('rate').value
    } as InvoiceCurrency ;
    console.log('currency', currency);
    this.changeCurrency.emit(currency);
    this.close();
  }
}
