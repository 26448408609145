import {PaymentTerm} from '@shared/models/payment-term.model';

import {WorkOrderDefinition} from '@control-tower/models/work-order/work-order-definition.model';
import {ServiceViewModel} from '../models/service-view.model';
import {ConfigType} from '@core/models/enums/config-type.enum';
import {EntityMap} from '@shared/models/types';
import {RequiredDocumentType} from '../models/enums/required-document-type.enum';
import {FILE_ACTION_PREFIX} from '@control-tower/store/file/file.action';
import {User} from "@core/models/user.model";
import {CollaboratorsInvitationModel} from "../models/collaborators-invitation.model";
import { DistributionWorkflowData } from '../models/distribution-workflow-data.model';
import { OptimizationInfo } from '../models/optimization-info.model';
import { RouteOptions } from '../models/route-options.model';

export const WIZARD_ACTION_PREFIX = '[Wizard State]';

export class LoadJobFileAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load JobFile`;
  constructor(public jobRefId: string) { }
}

export class FetchWorkFlowConfigFromJobFile {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load Workflow config from JobFile`;
}

export class FetchWorkFlowData {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load Workflow data`;
}

export class GenerateJobFileCompletionSteps {
  static readonly type = `${WIZARD_ACTION_PREFIX} Generate jobFile completionSteps`;
}

export class GenerateMandatoryFieldMap {
  static readonly type = `${WIZARD_ACTION_PREFIX} Generate Mandatory Fields Map`;
}

export class UseExpresswayAction{
  static readonly type = `${WIZARD_ACTION_PREFIX} Set Use Expressway value`;
  constructor(public useExpressWay: boolean) { }
}

export class LoadRateCards {
  static readonly type = `${WIZARD_ACTION_PREFIX} Fetch Rate Cards`;
}

export class AddContractedRateToJobFileAction{
  static readonly type = `${WIZARD_ACTION_PREFIX} Add PaymentTerm and RateCard to job JobFile`;
  constructor(public order: string, public paymentTerm: PaymentTerm, public rateCardId: string) { }
}

export class LoadWorkOrderDefinitionsAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load Work Order Definitions`;
  constructor(public workOrderIds: string[]) { }
}

export class LoadChildrenWorkOrderDefinitionsAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load Work Order Definitions`;
  constructor(public workOrderIds: string[]) { }
}

export class LoadSelectedActivityAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Load Selected Activity`;
  constructor(public selectedActivity: WorkOrderDefinition) { }
}

export class GetActivityDefinitionsAction {
  static readonly  type = `${WIZARD_ACTION_PREFIX} Get activity list`;
}

export class SaveActivityAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Save Activity`;
  constructor(public saveServiceViewModel: ServiceViewModel) { }
}

export class ProcessJobFileCompletionSteps {
  static readonly type = `${WIZARD_ACTION_PREFIX} Process completionSteps`;
}

export class ProcessMandatoryFieldMap {
  static readonly type = `${WIZARD_ACTION_PREFIX} Process mandatory field map`;
}

export class DownloadJobSummary {
  static readonly type = `${WIZARD_ACTION_PREFIX} Download job summary`;
  constructor(public order: string) { }
}

export class GenerateJobSummaryPDF {
  static readonly type = `${WIZARD_ACTION_PREFIX} Generate job summary PDF`;
  constructor(public order: string) { }
}

export class GenerateJobSummaryPDFAsync {
  static readonly type = `${WIZARD_ACTION_PREFIX} Generate job summary PDF Async`;
  constructor(public order: string) { }
}

export class GenerateCollaboratorsAsync {
  static readonly type = `${WIZARD_ACTION_PREFIX} Generate collaborators Async`;
  constructor(public order: string) { }
}

export class GenerateQuotationPDF {
  static readonly type = `${WIZARD_ACTION_PREFIX} Generate Quotation PDF`;
  constructor(public order: string) { }
}

export class GenerateContractPDF {
  static readonly type = `${WIZARD_ACTION_PREFIX} Generate Contract PDF`;
  constructor(public order: string) { }
}

export class GetCustomerInfo {
  static readonly type = `${WIZARD_ACTION_PREFIX} Get Customer Info`;
}

export class FetchWorkFlowSteps {
  static readonly type = `${WIZARD_ACTION_PREFIX} Fetch Workflow Steps`;
}

export class GetCustomsChannels {
  static readonly type = `${WIZARD_ACTION_PREFIX} Get Customs Channels`;
}

export class LoadJobSummaryAction{
  static readonly type = `${WIZARD_ACTION_PREFIX} Get Job Summary`;
  constructor(public jobRefId: string, public order: string) { }
}

export class FinalizeJobFile {
  static readonly type = `${WIZARD_ACTION_PREFIX} Finalize Job File`;
  constructor(public order: string){}
}

export class GetConfigAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} Get config`;
  constructor(public workflowId: string, public configType: ConfigType) { }
}

export class SendForApprovalJob{
  static readonly type = `${WIZARD_ACTION_PREFIX} job send for approval`;
  constructor(public order: string) { }
}

export class LoadWorkflowDefinitionV2{
  static readonly type = `${WIZARD_ACTION_PREFIX} load workflow definition`;
}

export class NextAction{
  static readonly type = `${WIZARD_ACTION_PREFIX} click next button`;
}

export class DisableValidation {
  static readonly type = `${WIZARD_ACTION_PREFIX} disable validation`;
  constructor(public valid: boolean){}
}

export class SaveWizardRowData {
  static readonly type = `${WIZARD_ACTION_PREFIX} save wizard row data`;
  constructor(public rowData: EntityMap<string, string>) {}
}

export class SaveWizardVehicleCapacitiesData {
  static readonly type = `${WIZARD_ACTION_PREFIX} save wizard vehicle capacities data`;
  constructor(public vehicleCapacities: EntityMap<string, {[lengthType: number]: number}>) {}
}

export class SaveDistributionWorkflowDataAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} save distribution workflow data`;
  constructor(public distributionWorkflowData: DistributionWorkflowData) {}
}

export class DownloadPackingListExcelAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} download packing list excel`;
  constructor(public containerId: string) { }
}

export class DownloadPackingListZipAction {
  static readonly type = `${WIZARD_ACTION_PREFIX} download all packing lists as zip`;
  constructor() { }
}

export class UploadWizardAttachments {
  static readonly type = `${WIZARD_ACTION_PREFIX} upload wizard documents`;
  constructor(public files: File[], public path: string) {}
}

export class RemoveWizardAttachment {
  static readonly type = `${WIZARD_ACTION_PREFIX} remove wizard documents`;
  constructor( public attachmentId: string) {}
}

export class UploadWizardRequiredDocument {
  static readonly type = `${WIZARD_ACTION_PREFIX} upload wizard required document`;
  constructor(public file: File, public path: string, public type: RequiredDocumentType) {}
}

export class LoadWizardDocumentAction {
  static readonly type = `${FILE_ACTION_PREFIX} Load wizard document`;
  constructor(public fileId: string) {}
}

export class FetchCollaborators {
  static readonly type = `${WIZARD_ACTION_PREFIX} Fetch collaborators`;
  constructor(public scopes: string[], public searchText?: string) {}
}

export class InviteCollaborators {
  static readonly type = `${WIZARD_ACTION_PREFIX} Invite collaborators`;
  constructor(public collaboratorsInvitationModel: CollaboratorsInvitationModel) {}
}

export class RemoveCollaborator {
  static readonly type = `${WIZARD_ACTION_PREFIX} Remove collaborator`;
  constructor(public collaboratorId: string) {}
}

export class ResetWizardState {
  static readonly type = `${WIZARD_ACTION_PREFIX} Reset wizard state`;
}

export class DeleteShipmentOrderJobFile {
  static readonly type = `${WIZARD_ACTION_PREFIX} Delete shipment order spec from jobfile`;
  constructor(public shipmentOrderId: string) { }
}

export class DeleteAllShipmentOrdersJobFile {
  static readonly type = `${WIZARD_ACTION_PREFIX} Delete all shipment order specs from jobfile`
}

export class ChangeLocationOrder {
  static readonly type = `${WIZARD_ACTION_PREFIX} Change Location Order`;
  constructor(public loadId: string, public prevIndex: number, public currIndex: number) { }
}

export class DownloadConsolidatedLoadManifest {
  static readonly type = `${WIZARD_ACTION_PREFIX} Download Consolidated Load Manifest`;
  constructor(public orgId: string, public jobRefId: string) { }
}

export class DownloadLoadManifest {
  static readonly type = `${WIZARD_ACTION_PREFIX} Download Load Manifest`;
  constructor(public orgId: string, public jobRefId: string) { }
}

export class SaveOtherReferenceNumber {
  static readonly type = `${WIZARD_ACTION_PREFIX} save reference number`;
  constructor(public orgId: string, public jobRefId: string, public containerId:string, public locationId:string, public serviceId:string, public refNumbers: string[], public workOrderId?: string) {}
}
export class SaveOrderReferenceNumber {
  static readonly type = `${WIZARD_ACTION_PREFIX} save order reference number`;
  constructor(public orgId: string, public jobRefId: string, public containerId:string, public locationId:string, public serviceId:string, public refNumbers: string[], public workOrderId?: string) {}
}
export class DeleteOtherReferenceNumber {
  static readonly type = `${WIZARD_ACTION_PREFIX} delete reference number`;
  constructor(public orgId: string, public jobRefId: string, public containerId:string, public locationId:string, public serviceId:string, public refNumber:string, public workOrderId?: string) {}
}

export class setOptimizationInfo {
  static readonly type = `${WIZARD_ACTION_PREFIX} set optimization info`;
  constructor(public selectedOptimizationInfo: OptimizationInfo) {}
}

export class setRouteOptions {
  static readonly type = `${WIZARD_ACTION_PREFIX} set route options`;
  constructor(public routeOptions: RouteOptions) {}
}