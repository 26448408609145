import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, tap} from 'rxjs/operators';
import {AlertDialogType} from '@shared/models';
import {OrganizationLocation} from '../../../modules/organization/models/organization-location';
import {AddCostComponent} from '../../../modules/invoice/components/add-cost/add-cost.component';
import {ValidationMessageComponent} from '@shared/components/validation-message/validation-message.component';
import {DialogHandlerService} from '@core/services/dialog-handler.service';

@Component({
  selector: 'hmt-distance-matrix',
  templateUrl: './distance-matrix.component.html',
  styleUrls: ['./distance-matrix.component.scss']
})
export class DistanceMatrixComponent implements OnInit {
  @Output() saveDistanceMatrix = new EventEmitter<{ distanceMatrix: number[][], locations: string[] }>();
  @Output() searchLocation = new EventEmitter<string>();
  locationTemplate: OrganizationLocation = {
    address: undefined,
    countryCode: '',
    deleted: false,
    deliveryOperatingHours: undefined,
    division: '',
    email: '',
    facilityType: undefined,
    geoLine: undefined,
    geofence: undefined,
    geolocation: undefined,
    locationDescription: '',
    locationName: '',
    locationOperationTimesInMinutes: undefined,
    locationPhones: [],
    locationType: undefined,
    openOperatingHours: undefined,
    operatedBy: '',
    orgId: '',
    parentLocationName: '',
    pickupOperatingHours: undefined,
    principalContact: undefined,
    purposes: [],
    referenceId: '',
    searchedAddress: '',
    secCenter: undefined,
    secRadius: 0,
    vehicleAccessibility: undefined,
    website: '',
    withinLocationId: ''
  };
  selectedLocations: OrganizationLocation[] = [this.locationTemplate, this.locationTemplate];
  distanceMatrix: number[][] = [
    [0, 0],
    [0, 0]
  ];
  locationForm: FormGroup;
  matrixForms: FormGroup[] = [];
  filteredOptions: Observable<string[]>;
  locations: OrganizationLocation[];
  cachedLocations: OrganizationLocation[];
  isFormVisible: boolean[] = [];

  constructor(
    public dialogRef: MatDialogRef<DistanceMatrixComponent>,
    public dialogHandlerService: DialogHandlerService,
    @Inject(MAT_DIALOG_DATA) public data: {
      locations: Observable<OrganizationLocation[]>,
      cachedLocations: Observable<OrganizationLocation[]>,
      selectedLocations: string[],
      distanceMatrix: number[][],
      isDisabledForm: boolean
    },
    private fb: FormBuilder) {
    this.locationForm = this.fb.group({
      location: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.locationForm.get('location').valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      tap(searchValue => {
        this.searchLocation.emit(searchValue);
      }),
    ).subscribe();

    this.data.locations.pipe(
      tap(locations => {
        this.locations = locations;
      })
    ).subscribe();

    this.data.cachedLocations.pipe(
      tap(locations => {
        this.cachedLocations = locations;
      })
    ).subscribe();
    if (this.data.distanceMatrix != null) {
      this.distanceMatrix = JSON.parse(JSON.stringify(this.data.distanceMatrix));
    }
    if (this.data.selectedLocations != null) {
      this.selectedLocations = this.data.selectedLocations?.map(id => {
        return this.cachedLocations.find(location => location.id === id);
      });
    }
    this.isFormVisible = new Array(this.selectedLocations.length).fill(false);
    this.initializeMatrixForms();

    if (this.data.isDisabledForm){
      this.locationForm.disable();
      this.matrixForms.forEach((formGroup) => {
        formGroup.disable();
      });
    }
  }

  initializeMatrixForms() {
    this.matrixForms = [];
    this.matrixForms = this.distanceMatrix.map((row, i) =>
      this.fb.group(row.reduce((acc, _, j) => {
        if (i !== j) {
          acc[this.getControlName(i, j)] = [this.distanceMatrix[i][j], [Validators.required, Validators.min(1)]];
        }
        return acc;
      }, {}))
    );
  }


  getControlName(i: number, j: number): string {
    return `cell_${i}_${j}`;
  }

  displayLocationName(location: OrganizationLocation): string {
    if (location == null) {
      return null;
    }

    return location.locationName;
  }

  toggleFormVisibility(index: number): void {
    this.isFormVisible[index] = !this.isFormVisible[index];
  }

  showValidation(message: string){
    const data = {message};
    const dialogRef = this.dialogHandlerService.openDialog(ValidationMessageComponent, data, {
      height: '250px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: {
        top: '10%',
        left: '35%'
      },
    });
  }

  updateLocation(index) {

    if (this.locationForm.valid) {
      const location = this.locationForm.get('location')?.value;
      const selectedLocation = this.cachedLocations?.find(loc => loc.id === location.id);
      if (selectedLocation == null) {
        return;
      }

      if (this.selectedLocations.some(item => item?.id === selectedLocation.id)){
        this.showValidation('User should not be able to add the same locations twice');
        return;
      }

      this.selectedLocations[index] = selectedLocation;
      this.matrixForms.forEach((formGroup, i) => {
        const controlNameVertical = this.getControlName(i, index);
        if (Object.keys(formGroup.controls).includes(controlNameVertical)) {
          formGroup.patchValue({[controlNameVertical]: ''});
        }
      });

      const newFormGroup = this.fb.group({});
      for (let j = 0; j < this.selectedLocations.length; j++) {
        if (index !== j) {
          const controlName = this.getControlName(index, j);
          newFormGroup.addControl(controlName, this.fb.control('', [Validators.required, Validators.min(1)]));
        }
      }
      this.matrixForms[index] = newFormGroup;
      this.isFormVisible[index] = false;
      this.locationForm.reset();
    }
  }


  addLocation() {
    this.selectedLocations.push(null);
    // Add a new row and column to the distanceMatrix
    this.distanceMatrix.forEach(row => {
      row.push(0);
    });
    const newRow = new Array(this.selectedLocations.length).fill(0);
    this.distanceMatrix.push(newRow);

    // Add new controls for the new location to matrixForms
    this.matrixForms.forEach((formGroup, i) => {
      const controlName = this.getControlName(i, this.selectedLocations.length - 1);
      formGroup.addControl(controlName, this.fb.control('', [Validators.required, Validators.min(1)]));
    });

    const newFormGroup = this.fb.group({});
    for (let j = 0; j < this.selectedLocations.length; j++) {
      if (this.matrixForms.length !== j) {
        const controlName = this.getControlName(this.matrixForms.length, j);
        newFormGroup.addControl(controlName, this.fb.control('', [Validators.required, Validators.min(1)]));
      }
    }
    this.matrixForms.push(newFormGroup);

    this.locationForm.reset();
    this.isFormVisible.push(false);
  }


  removeLocation(index: number) {
    this.matrixForms.forEach((form, rowIndex) => {
      const formValues = form.value;
      for (const key in formValues) {
        if (formValues.hasOwnProperty(key)) {
          const [i, j] = key.split('_').slice(1).map(t => +t);
          this.distanceMatrix[i][j] = +formValues[key];
        }
      }
    });
    this.selectedLocations.splice(index, 1);
    this.distanceMatrix.splice(index, 1);
    this.distanceMatrix.forEach(row => row.splice(index, 1));
    this.matrixForms = [];
    // this.matrixForms.splice(index, 1);
    this.isFormVisible.splice(index, 1);
    // this.matrixForms.forEach((formGroup, i) => {
    //   const controlName = this.getControlName(i, index);
    //   formGroup.removeControl(controlName);
    // });
    this.initializeMatrixForms();
  }

  getRowFormGroup(index: number): FormGroup {
    return this.matrixForms[index];
  }

  close() {
    this.dialogRef.close();
  }

  saveMatrix() {
    if (!this.isMatrixFormsValid()){
      this.showValidation('Distance should not be Empty');
      return;
    }

    if (this.selectedLocations.some(item => item == null)){
      this.showValidation('Locations should not be Empty');
      return;
    }
    const newDistanceMatrix = this.distanceMatrix.map(row => [...row]);
    this.matrixForms.forEach((form, rowIndex) => {
      const formValues = form.value;
      for (const key in formValues) {
        if (formValues.hasOwnProperty(key)) {
          const [i, j] = key.split('_').slice(1).map(t => +t);
          newDistanceMatrix[i][j] = +formValues[key];
        }
      }
    });
    this.saveDistanceMatrix.emit({distanceMatrix: newDistanceMatrix, locations: this.selectedLocations.map(l => l.id)});
    this.close();
  }

  isMatrixFormsValid(): boolean {
    return this.matrixForms.every((formGroup, i) => {
      return Object.keys(formGroup.controls).every(key => {
        return formGroup.controls[key].valid;
      });
    });
  }
}
