import {Component, OnInit} from '@angular/core';
import {debounceTime, distinctUntilChanged, mergeMap, startWith, take, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Actions, ofActionSuccessful, Select, Store} from '@ngxs/store';
import {InvoiceState} from '../../store/invoice/invoice.state';
import {Observable, Subject, forkJoin} from 'rxjs';
import {Invoice} from '@shared/models/invoice/invoice.model';
import {
  ChangeCurrencyAction,
  FindAllCurrencyAction,
  FindInvoiceByIdAction,
  GetAllTaxByCountryAction,
  GetInvoiceLoadsLimit,
  GetOrgDetailsAction,
  GetRateCardsByIds,
  RemoveAllLoadsAction,
  RemoveLoadAction,
  UpdateInvoiceAction,
  UploadPdfAndGetId
} from '../../store/invoice/invoice.actions';
import {AuthState} from '@core/store/auth/auth.state';
import {User} from '@core/models/user.model';
import {EntityMap} from '@shared/models/types';
import {OrgDetails} from '@shared/models/org-details.model';
import {InvoiceLoad} from '../../models/invoice-load.model';
import {DialogHandlerService} from '@core/services/dialog-handler.service';
import {AddCostComponent} from '../../components/add-cost/add-cost.component';
import {Cost} from '../../models/cost.model';
import {Step} from '@shared/models/step.model';
import {ProgressLineStepType} from '../../models/enums/progress-line-step.enum';
import {ApprovalStateType} from '../../models/enums/approval-state.enum';
import {OverdueStateType} from '../../models/enums/overdue-state.enum';
import {TabType} from '../../models/enums/invoice-state.enum';
import {PaymentType} from '../../models/enums/payment-type.enum';
import {AddAdditionAndSubtractionComponent} from '../../components/add-addition-and-subtraction/add-addition-and-subtraction.component';
import {AdditionAndSubtraction} from '../../models/addition-and-subtraction.model';
import {Tax} from '../../models/tax.model';
import {ChangeCurrencyComponent} from '../../components/change-currency/change-currency.component';
import {InvoiceCurrency} from '../../models/invoice-currency.model';
import {Currency} from '@shared/models/currency.model';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {RateCard} from '@shared/models/rate-card.model';
import {PaymentTerm} from '@shared/models/payment-term.model';
import {FormControl, FormGroup} from '@angular/forms';
import {Location} from '@angular/common';
import {InvoiceExtraService} from '../../services/invoice-extra.service';
import {InvoiceType} from '../../models/enums/invoice-type.enum';

@Component({
  selector: 'hmt-invoice-progress-line',
  templateUrl: './invoice-progress-line.component.html',
  styleUrls: ['./invoice-progress-line.component.scss']
})
export class InvoiceProgressLineComponent implements OnInit {
  @Select(InvoiceState.getSelectedInvoice) invoice$: Observable<Invoice>;
  @Select(InvoiceState.getOrgDetails) orgDetails$: Observable<EntityMap<string, OrgDetails>>;
  @Select(InvoiceState.getAllTaxes) taxes$: Observable<Tax[]>;
  @Select(InvoiceState.getCurrencies) currencies$: Observable<Currency[]>;
  @Select(AuthState.getUser) user$: Observable<User>;
  @Select(InvoiceState.getRateCardsMap) rateCardsMap$: Observable<EntityMap<string, RateCard>>;
  @Select(InvoiceState.getInvoiceLoadsLimit) loadsLimit$: Observable<number>;
  private unsubscribe: Subject<void> = new Subject();
  user: User;
  invoice: Invoice;
  orgIds: string[];
  progressLineStep = ProgressLineStepType;
  approvalStep = ApprovalStateType;
  invoiceStep = TabType;
  overdueStep = OverdueStateType;
  paymentType = PaymentType;
  totalsAfterTax: number[] = [];
  taxAmounts: number[] = [];
  rateCardContainerIdsMap: EntityMap<string, string[]> = {};
  rateCardIds: string[];
  oldCurrencyRate = 0;
  tenantLoadsLength = 5;
  currencyMap: EntityMap<string, string> = {};
  steps: Step[] = this.calculateRatesService.invoicePLSteps;
  groupByJobTitle: EntityMap<string, InvoiceLoad[]> = {};
  groups: string[] = [];
  suspendedVat = false;
  taxes: AdditionAndSubtraction[];
  toggled = true;
  rateForm: FormGroup;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store, private action$: Actions,
    public dialogHandlerService: DialogHandlerService,
    private location: Location,
    public calculateRatesService: InvoiceExtraService
  ) {
  }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.rateForm = new FormGroup({
      rate: new FormControl(''),
    });
    this.user$.pipe(
      takeUntil(this.unsubscribe),
      tap((user: User) => {
        this.user = user;
      })
    ).subscribe();
    this.route.queryParams
      .pipe(
        takeUntil(this.unsubscribe),
        tap((params) => {
          this.store$.dispatch(new FindInvoiceByIdAction(this.user.orgId, params[`id`]));
        }),
      ).subscribe();
    this.invoice$.pipe(
      takeUntil(this.unsubscribe),
      tap((invoice: Invoice) => {
        this.orgIds = [invoice?.createdTo, invoice?.serviceProvider];
        const observables = [
          this.store$.dispatch(new GetOrgDetailsAction(this.orgIds)),
          this.store$.dispatch(new GetInvoiceLoadsLimit(this.user.orgId))
        ];
        forkJoin(observables).subscribe(() => {
        this.invoice = invoice;
        if (this.invoice?.loads?.length > 0) {
          this.groupByJobTitle = this.invoice.loads.reduce((group, load) => {
            const {jobTitle} = load;
            group[jobTitle] = group[jobTitle] ?? [];
            group[jobTitle].push(load);
            return group;
          }, {});
          this.groups = Object.keys(this.groupByJobTitle);

          this.rateCardContainerIdsMap = this.invoice.loads.reduce((group, load) => {
            const {rateCard} = load;
            group[rateCard] = group[rateCard] ?? [];
            group[rateCard].push(load.loadNumber ? load.loadNumber : load.loadId);
            return group;
          }, {});
          this.rateCardIds = Object.keys(this.rateCardContainerIdsMap);
          this.store$.dispatch(new GetRateCardsByIds(this.rateCardIds));
        }
        else{
          this.groupByJobTitle = {};
        }
        if (this.invoice != null) {
          this.rateForm.controls.rate.setValue(this.invoice.exchangeRate);
          this.suspendedVat = invoice.suspendedVat;
          this.taxes = invoice.taxes;
          this.calculateTax();

        }
        });
      })
    ).subscribe();

    this.rateForm.get('rate')
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        startWith(''),
        debounceTime(1000),
        distinctUntilChanged(),
        tap((rate: number) => {
          this.invoice = {...this.invoice, exchangeRate: rate};
          this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
        })
      )
      .subscribe();

    this.store$.dispatch(new GetAllTaxByCountryAction('LK'));
    this.store$.dispatch(new FindAllCurrencyAction());

    this.action$.pipe(
      ofActionSuccessful(ChangeCurrencyAction),
      takeUntil(this.unsubscribe),
      tap(() => {
        const pt =  {...this.invoice.invoicePaymentTerm};
        pt.advance = (pt.advance * this.oldCurrencyRate) / this.invoice.currency.currencyRate;
        const invoicePaymentTermStr = this.calculateRatesService.generatePaymentTermString(pt, this.invoice.currency, this.getTotal());
        this.invoice = {...this.invoice, invoicePaymentTermStr, invoicePaymentTerm: pt};
        this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
      })
    ).subscribe();

    this.action$.pipe(
      ofActionSuccessful(GetInvoiceLoadsLimit),
      take(1),
      mergeMap(() => {
        return this.loadsLimit$;
      }),
      tap((limit: number) => {
        this.tenantLoadsLength = limit;
        // this.toggled = (this.invoice?.loads?.length <= this.tenantLoadsLength);
      })
    ).subscribe();

    this.currencies$.pipe(
      takeUntil(this.unsubscribe),
      tap(currencies => {
        this.currencyMap = currencies.reduce((map, ic) => {
          map[ic.code] = ic.shortCode;
          return map;
        }, {});
      })
    ).subscribe();
  }
  
  removeLoadSubTotal(loadId: string){
    const loads = [...this.invoice.loads];
    const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
    loads[index] = {...loads[index], addedOtherCost: [], addedDiscount:null};
    this.invoice = {...this.invoice, loads};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  removeLoadDiscount( loadId: string){
    const loads = [...this.invoice.loads];
    const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
    loads[index] = {...loads[index], addedDiscount: null};
    this.invoice = {...this.invoice, loads};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  removeLoadOtherCost(id: string, loadId: string) {
    const loads = [...this.invoice.loads];
    const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
    const currentOtherCosts = this.invoice.loads[index]?.addedOtherCost || [];
    loads[index] = { ...loads[index], addedOtherCost: currentOtherCosts.filter(cost => cost.id !== id) };
    this.invoice = { ...this.invoice, loads };
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }
  
  removeLoadAdditionalCost(id: string, loadId: string) {
    const loads = [...this.invoice.loads];
    const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
    const currentAdditionalCosts = this.invoice.loads[index]?.addedAdditionalCost || [];
    loads[index] = { ...loads[index], addedAdditionalCost: currentAdditionalCosts.filter(cost => cost.id !== id) };
    this.invoice = { ...this.invoice, loads };
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  removeCost(id: string, loadId: string) {
    const loads = [...this.invoice.loads];
    const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
    loads[index] = {...loads[index], costs: [...this.invoice.loads[index].costs.filter(cost => cost.id !== id)]};
    this.invoice = {...this.invoice, loads};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }


  addLoadAdditionalCost(loadId: string, lineItem: Cost) {
    const data = { cost: lineItem };
    const dialogRef = this.dialogHandlerService.openDialog(AddCostComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: { top: '10%', left: '35%' },
    });
  
    dialogRef.componentInstance.addCost
      .pipe(
        takeUntil(this.unsubscribe),
        tap((cost: Cost) => {
          cost.value = this.convertToDefaultCurrency(cost.value);
          const loads = [...this.invoice.loads];
          const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
          const currentAdditionalCosts = this.invoice.loads[index]?.addedAdditionalCost || [];
          if (lineItem == null) {
            loads[index] = { ...loads[index], addedAdditionalCost: [...currentAdditionalCosts, cost] };
          } else {
            const addedAdditionalCost = currentAdditionalCosts.map(value => {
              if (cost.id === value.id) {
                value = cost;
              }
              return value;
            });
            loads[index] = { ...loads[index], addedAdditionalCost };
          }
          this.invoice = { ...this.invoice, loads };
          this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
        })
      ).subscribe();
  }
  

  addCost(loadId: string, lineItem: Cost) {
    const data = {cost: lineItem};
    const dialogRef = this.dialogHandlerService.openDialog(AddCostComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: {
        top: '10%',
        left: '35%'
      },
    });

    dialogRef.componentInstance.addCost
      .pipe(
        takeUntil(this.unsubscribe),
        tap((cost: Cost) => {
          cost.value = this.convertToDefaultCurrency(cost.value);
          const loads = [...this.invoice.loads];
          const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
          if (lineItem == null) {
            loads[index] = {...loads[index], costs: [...this.invoice.loads[index].costs, cost]};
          } else {
            const costs = [...this.invoice.loads[index].costs].map(value => {
              if (cost.id === value.id) {
                value = cost;
              }
              return value;
            });
            loads[index] = {...loads[index], costs};
          }
          this.invoice = {...this.invoice, loads};
          this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
        })
      ).subscribe();
  }

  removeLoad(id: string, invoiceId: string) {
    this.store$.dispatch(new RemoveLoadAction(id, InvoiceType.RECEIVABLE, invoiceId));
  }

  addAdditionalCost() {
    const data = {};
    const dialogRef = this.dialogHandlerService.openDialog(AddCostComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: {
        top: '10%',
        left: '35%'
      },
    });

    dialogRef.componentInstance.addCost.pipe(
      takeUntil(this.unsubscribe),
      tap((cost: Cost) => {
        cost.value = this.convertToDefaultCurrency(cost.value);
        this.invoice = {...this.invoice, additionalCosts: [...this.invoice.additionalCosts, cost]};
        this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
      })
    ).subscribe();
  }

  removeAdditionalCost(id: string) {
    this.invoice = {...this.invoice, additionalCosts: [...this.invoice.additionalCosts.filter(cost => cost.id !== id)]};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  calculateTotalOfLoadsCost() {
    return this.invoice?.loads.reduce((total, load) => total + load.loadCost, 0);
  }

  onSaveInvoice() {
    this.invoice = {...this.invoice, total: +(this.totalsAfterTax[this.totalsAfterTax.length - 1])?.toFixed(2)};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  openLoadAddItem(loadId: string, item: Cost) {
    const data = item != null ? {
      paymentType: item.paymentType ?? PaymentType.AMOUNT,
      additionAndSubtraction: item,
      type: 'discount',
      taxes: this.taxes$,
      name: "Add Fee"
    } : {
      paymentType: PaymentType.AMOUNT,
      additionAndSubtraction: null,
      type: 'discount',
      taxes: this.taxes$,
      name: "Add Fee"
    };
  
    const dialogRef = this.dialogHandlerService.openDialog(AddAdditionAndSubtractionComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: { top: '10%', left: '35%' },
    });
  
    dialogRef.componentInstance.addAdditionAndSubtraction.pipe(
      takeUntil(this.unsubscribe),
      tap((cost: AdditionAndSubtraction) => {
        cost.value = this.convertToDefaultCurrency(cost.value);
        const loads = [...this.invoice.loads];
        const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
        const currentOtherCosts = this.invoice.loads[index]?.addedOtherCost || [];
        if (item == null) {
          loads[index] = { ...loads[index], addedOtherCost: [...currentOtherCosts, cost] };
        } else {
          const addedOtherCost = currentOtherCosts.map(value => {
            if (cost.id === value.id) {
              value = cost;
            }
            return value;
          });
          loads[index] = { ...loads[index], addedOtherCost };
        }
        this.invoice = { ...this.invoice, loads };
        this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
      })
    ).subscribe();
  }
  
  openLoadAddDiscount(loadId: string, item: Cost) {
    const data = item != null ? {
      paymentType: item.paymentType ?? PaymentType.AMOUNT,
      additionAndSubtraction: item,
      type: 'discount',
      taxes: this.taxes$,
      name: "Add Discount"
    } : {
      paymentType: PaymentType.AMOUNT,
      additionAndSubtraction: null,
      type: 'discount',
      taxes: this.taxes$,
      name: "Add Discount"
    };
  
    const dialogRef = this.dialogHandlerService.openDialog(AddAdditionAndSubtractionComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: { top: '10%', left: '35%' },
    });
  
    dialogRef.componentInstance.addAdditionAndSubtraction.pipe(
      takeUntil(this.unsubscribe),
      tap((cost: AdditionAndSubtraction) => {
        cost.value = this.convertToDefaultCurrency(cost.value);
        const loads = [...this.invoice.loads];
        const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
        loads[index] = { ...loads[index], addedDiscount: cost };
        this.invoice = { ...this.invoice, loads };
        this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
      })
    ).subscribe();
  }
  


  openAddItem() {
    const data = {
      paymentType: PaymentType.AMOUNT,
      additionAndSubtraction: null,
      type: 'discount',
      taxes: this.taxes$,
      name: "Add Fee"
    };
    const dialogRef = this.dialogHandlerService.openDialog(AddAdditionAndSubtractionComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: {
        top: '10%',
        left: '35%'
      },
    });

    dialogRef.componentInstance.addAdditionAndSubtraction.pipe(
      takeUntil(this.unsubscribe),
      tap((additionAndSubtraction: AdditionAndSubtraction) => {

        const cost ={
          value : additionAndSubtraction?.value,
          paymentType : additionAndSubtraction?.paymentType,
          id : additionAndSubtraction?.id,
          description : additionAndSubtraction?.description
        }
        if (additionAndSubtraction.paymentType === PaymentType.AMOUNT) {
          cost.value = this.convertToDefaultCurrency(additionAndSubtraction.value);
        }

        this.invoice = {...this.invoice, otherCosts: [...this.invoice.otherCosts, cost]};
        this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
      })
    ).subscribe();

  }

  openAddDiscount() {
    const data = {
      paymentType: PaymentType.AMOUNT,
      additionAndSubtraction: null,
      type: 'discount',
      taxes: this.taxes$,
      name: "Add Discount"
    };
    const dialogRef = this.dialogHandlerService.openDialog(AddAdditionAndSubtractionComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: {
        top: '10%',
        left: '35%'
      },
    });

    dialogRef.componentInstance.addAdditionAndSubtraction.pipe(
      takeUntil(this.unsubscribe),
      tap((additionAndSubtraction: AdditionAndSubtraction) => {
        if (additionAndSubtraction.paymentType === PaymentType.AMOUNT) {
          additionAndSubtraction.value = this.convertToDefaultCurrency(additionAndSubtraction.value);
        }
        this.invoice = {...this.invoice, discount: additionAndSubtraction};
        this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
      })
    ).subscribe();
  }

  editDiscount(discount: AdditionAndSubtraction) {
    if (discount.paymentType === PaymentType.AMOUNT) {
      discount.value = this.convertToDefaultCurrency(discount.value);
    }
    this.invoice = {...this.invoice, discount};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  openAddTax() {
    const data = {
      paymentType: PaymentType.AMOUNT,
      additionAndSubtraction: null,
      type: 'tax',
      taxes: this.taxes$
    };
    const dialogRef = this.dialogHandlerService.openDialog(AddAdditionAndSubtractionComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: {
        top: '10%',
        left: '35%'
      },
    });

    dialogRef.componentInstance.addAdditionAndSubtraction.pipe(
      takeUntil(this.unsubscribe),
      tap((event: AdditionAndSubtraction) => {
        this.invoice = {...this.invoice, taxes: [...this.invoice.taxes, event]};
        this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
      })
    ).subscribe();
  }

  openEditTax(tax: AdditionAndSubtraction) {
    const data = {
      paymentType: tax.paymentType,
      additionAndSubtraction: tax,
      type: 'tax',
      taxes: this.taxes$
    };
    const dialogRef = this.dialogHandlerService.openDialog(AddAdditionAndSubtractionComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: {
        top: '10%',
        left: '35%'
      },
    });

    dialogRef.componentInstance.addAdditionAndSubtraction.pipe(
      takeUntil(this.unsubscribe),
      tap((event: AdditionAndSubtraction) => {
        const index = this.invoice.taxes.findIndex(t => t.id === tax.id);
        const taxes = [...this.invoice.taxes];
        taxes[index] = event;
        this.invoice = {...this.invoice, taxes};
        this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
      })
    ).subscribe();
  }

  calculateAddedDiscountTotal(load: InvoiceLoad){
    const totalLoadCost = this.calculateLoadSubTotal(load);
    const calculatedDiscount = (totalLoadCost / 100) * (load?.addedDiscount == null ? 0 : load?.addedDiscount?.value);
    const totalLoadDiscountedCost = load.addedDiscount == null ? 0
      : (load?.addedDiscount?.paymentType === PaymentType.AMOUNT ? load?.addedDiscount?.value : calculatedDiscount);
    return totalLoadDiscountedCost;
  }

  removeAddedDiscountOfLoad(loadId: string){
    const loads = [...this.invoice.loads];
    const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
    loads[index] = {...loads[index], addedDiscount: null};
    this.invoice = {...this.invoice, loads};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  calculateAddedItemTotal(load: InvoiceLoad){
    const totalLoadCost = this.calculateLoadSubTotal(load);
    const totalLoadOtherCost = load?.addedOtherCost?.reduce(
      (total, cost) =>
        total +
        (cost?.paymentType === PaymentType.PERCENTAGE
          ? (totalLoadCost / 100) * (cost?.value || 0)
          : cost?.value || 0),
      0
    ) || 0;
    return totalLoadOtherCost;
  }

  removeAddedItemOfLoad(loadId: string){
    const loads = [...this.invoice.loads];
    const index = this.invoice.loads.findIndex(l => l.loadId === loadId);
    loads[index] = {...loads[index], addedOtherCost: []};
    this.invoice = {...this.invoice, loads};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }


  removeLineItem(lineItem: Cost) {
    this.invoice = {...this.invoice, otherCosts: [...this.invoice.otherCosts.filter(item => item.id !== lineItem.id)]};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  removeLoadsCost(id: string) {
    this.invoice = {...this.invoice, loadsCosts: [...this.invoice.loadsCosts.filter(cost => cost.id !== id)]};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  addLoadsCost(loadsCost: Cost) {
    const data = {cost: loadsCost};
    const dialogRef = this.dialogHandlerService.openDialog(AddCostComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: {
        top: '10%',
        left: '35%'
      },
    });

    dialogRef.componentInstance.addCost.pipe(
      takeUntil(this.unsubscribe),
      tap((cost: Cost) => {
        if (loadsCost == null){
          cost.value = this.convertToDefaultCurrency(cost.value);
          this.invoice = {...this.invoice, loadsCosts: [...this.invoice.loadsCosts, cost]};
        }
        else{
          const index  = this.invoice.loadsCosts.findIndex( item => item.id === loadsCost.id);
          cost.value = this.convertToDefaultCurrency(cost.value);
          const lc = [...this.invoice.loadsCosts];
          lc[index] = cost;
          this.invoice = {...this.invoice, loadsCosts: lc};
        }
        this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
      })
    ).subscribe();
  }

  removeDiscount() {
    this.invoice = {...this.invoice, discount: null};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  removeTax(id: string) {
    this.invoice = {...this.invoice, taxes: [...this.invoice.taxes.filter(tax => tax.id !== id)]};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }


  calculateLoadPercentage(load: InvoiceLoad, value: number) {
    const loadCost = load?.loadCost || 0;
    const loadExpense = (load?.costs?.reduce((total, load) => total + (load?.value || 0), 0) || 0) +
      (load?.addedAdditionalCost?.reduce((total, load) => total + (load?.value || 0), 0) || 0);
    return (loadCost + loadExpense) * value / 100;
  }
  
  calculateLoadSubTotal(load: InvoiceLoad) {
    const totalLoadCost =
      (load?.loadCost || 0) +
      (load?.costs?.reduce((totalCost, cost) => totalCost + (cost?.value || 0), 0) || 0) +
      (load?.addedAdditionalCost?.reduce((totalCost, cost) => totalCost + (cost?.value || 0), 0) || 0);
    
    return totalLoadCost;
  }  

  calculateTotalOfAllLoads() {
    return this.invoice?.loads.reduce((total, load) => total + this.calculateLoadTotal(load), 0);
  }

  calculateLoadTotal(load: InvoiceLoad) {
    const totalLoadCost = this.calculateLoadSubTotal(load);
    const totalLoadOtherCost = load?.addedOtherCost?.reduce(
      (total, cost) =>
        total +
        (cost?.paymentType === PaymentType.PERCENTAGE
          ? (totalLoadCost / 100) * (cost?.value || 0)
          : cost?.value || 0),
      0
    ) || 0;
    const calculatedDiscount = (totalLoadCost / 100) * (load?.addedDiscount == null ? 0 : load?.addedDiscount?.value);
    const totalLoadDiscountedCost = load.addedDiscount == null ? 0
      : (load?.addedDiscount?.paymentType === PaymentType.AMOUNT ? load?.addedDiscount?.value : calculatedDiscount);
    return totalLoadCost + totalLoadOtherCost - totalLoadDiscountedCost;
  }

  calculateServiceFee() {
    const additionalCostTotal = this.invoice?.additionalCosts.reduce((total, cost) => total + cost.value, 0);
    const loadCostTotal = this.invoice?.loads.reduce((total, load) => total + this.calculateLoadTotal(load), 0);
    return additionalCostTotal + loadCostTotal;
  }

  calculateLineItemValue(value : number) {
    const serviceFee = this.calculateServiceFee();
    return serviceFee / 100 * value;
  } 

  calculateDiscount() {
    const serviceFee = this.calculateServiceFee();
    return serviceFee / 100 * this.invoice.discount.value;
  }

  calculateSubTotal() {
    const serviceFee = this.calculateServiceFee();
    const lineItemsTotal = this.invoice?.otherCosts.reduce((total, cost) => total + (cost.paymentType === PaymentType.PERCENTAGE ? (serviceFee/100 * cost.value) : cost.value ), 0);
    const serviceFeePlusLineItems = serviceFee + lineItemsTotal;
    const calculatedDiscount = (serviceFee / 100) * (this.invoice?.discount == null ? 0 : this.invoice?.discount?.value);
    const discount = this.invoice?.discount == null ? 0
      : (this.invoice?.discount.paymentType === PaymentType.AMOUNT ? this.invoice?.discount?.value : calculatedDiscount);
    return serviceFeePlusLineItems - discount;
  }

  calculateTax() {
    const totalsAfterTax: number[] = [];
    const taxAmounts: number[] = [];
    const subTotal = this.calculateSubTotal();

    if (this.taxes.length <= 0) {
      totalsAfterTax[0] = subTotal;
    } else {
      if (this.suspendedVat) {
        this.taxes = this.invoice?.taxes.filter(tax => tax.description !== 'Vat');
      } else {
        this.taxes = this.invoice?.taxes;
      }
      if (this.taxes.length <= 0) {
        totalsAfterTax[0] = subTotal;
      }
      this.taxes.forEach((tax, index) => {
        if (index === 0) {
          totalsAfterTax[index]
            = tax.paymentType === PaymentType.AMOUNT ? (tax.value + subTotal) : ((subTotal / 100) * tax.value) + subTotal;
          taxAmounts[index] = tax.paymentType === PaymentType.AMOUNT ? tax.value : ((subTotal / 100) * tax.value);
        } else {
          totalsAfterTax[index] = tax.paymentType === PaymentType.AMOUNT ? (tax.value + totalsAfterTax[index - 1])
            : ((totalsAfterTax[index - 1] / 100) * tax.value) + totalsAfterTax[index - 1];
          taxAmounts[index] = tax.paymentType === PaymentType.AMOUNT ? tax.value : ((totalsAfterTax[index - 1] / 100) * tax.value);
        }
      });
    }
    this.totalsAfterTax = totalsAfterTax;
    this.taxAmounts = taxAmounts;
  }

  getTotal() {
    return this.taxes?.length > 0 ? this.totalsAfterTax[this.totalsAfterTax.length - 1] : this.calculateSubTotal();
  }

  onSubmitForApproval() {
    this.invoice = {
      ...this.invoice, progressStep: ProgressLineStepType.SENT_FOR_INTERNAL_APPROVAL,
      approvalState: ApprovalStateType.SEND_FOR_INTERNAL_APPROVAL,
      stateUpdatedAt: new Date()
    };
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  onInternalReject() {
    this.invoice = {
      ...this.invoice, progressStep: ProgressLineStepType.SENT_FOR_INTERNAL_APPROVAL,
      approvalState: ApprovalStateType.INTERNALLY_REJECTED,
      stateUpdatedAt: new Date()
    };
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  onInternalApprove() {
    this.invoice = {
      ...this.invoice, progressStep: ProgressLineStepType.INTERNAL_APPROVED,
      approvalState: ApprovalStateType.INTERNALLY_APPROVED,
      stateUpdatedAt: new Date()
    };
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  downloadPdf() {
    this.store$.dispatch(new UploadPdfAndGetId(this.user.orgId, this.invoice._id));
  }

  sentToCustomer() {
    this.invoice = {
      ...this.invoice, progressStep: ProgressLineStepType.SENT, tab: TabType.UNPAID,
      approvalState: ApprovalStateType.CUSTOMER_PENDING_APPROVAL,
      stateUpdatedAt: new Date()
    };
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  markAsOverdue() {
    this.invoice = {...this.invoice, overdueState: OverdueStateType.OVERDUE, tab: TabType.OVERDUE,
      stateUpdatedAt: new Date()};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  markAsPaid() {
    this.invoice = {
      ...this.invoice, progressStep: ProgressLineStepType.PAID, tab: TabType.PAID,
      approvalState: ApprovalStateType.PAID,
      stateUpdatedAt: new Date()
    };
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  onCustomerReject() {
    this.invoice = {...this.invoice, progressStep: ProgressLineStepType.SENT,
      approvalState: ApprovalStateType.CUSTOMER_REJECTED, stateUpdatedAt: new Date()};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  onCustomerApprove() {
    this.invoice = {...this.invoice, progressStep: ProgressLineStepType.SENT,
      approvalState: ApprovalStateType.CUSTOMER_APPROVED, stateUpdatedAt: new Date()};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  markAsDoubtful() {
    this.invoice = {...this.invoice, overdueState: OverdueStateType.DOUBTFUL,
      tab: TabType.OVERDUE, stateUpdatedAt: new Date()};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  markAsSubtended() {
    this.invoice = {...this.invoice, overdueState: OverdueStateType.SUBSTANDARD,
      tab: TabType.OVERDUE, stateUpdatedAt: new Date()};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  maskAsLost() {
    this.invoice = {...this.invoice, overdueState: OverdueStateType.LOSS,
      tab: TabType.OVERDUE, stateUpdatedAt: new Date()};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }

  backToInvoicePage() {
    this.location.back();
  }

  checkPermission(invoice: Invoice): boolean {
    return invoice.tab === this.invoiceStep.DRAFT
      || (invoice.tab === this.invoiceStep.UNPAID && invoice.approvalState === this.approvalStep.CUSTOMER_REJECTED);
  }

  removeAllLoads(id: string) {
    this.store$.dispatch(new RemoveAllLoadsAction(id, InvoiceType.RECEIVABLE));
  }

  getGroupByContainerType(invoiceLoadsForJob: InvoiceLoad[]): EntityMap<string, InvoiceLoad[]> {
    return invoiceLoadsForJob.reduce((group, load) => {
      const {containerType} = load;
      group[containerType] = group[containerType] ?? [];
      group[containerType].push(load);
      return group;
    }, {});
  }

  getContainerTypes(invoiceLoadsForJob: InvoiceLoad[]): string[] {
    const groupByContainerTypeMap = this.getGroupByContainerType(invoiceLoadsForJob);
    return Object.keys(groupByContainerTypeMap);
  }

  applyCurrency(value: number) {
    value = value / this.invoice.currency.currencyRate;
    const formattedNumber = value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return  this.invoice.currency.currentCurrency + ' ' + formattedNumber;
  }

  applyCurrencyWithoutCurrencyCode(value: number): number {
    return +(value / this.invoice.currency.currencyRate)?.toFixed(2);
  }

  convertToDefaultCurrency(value: number): number {
    if (this.invoice.currency.currentCurrency !== this.invoice.currency.defaultCurrency) {
      return value * this.invoice.currency.currencyRate;
    } else {
      return value;
    }
  }

  changeCurrency() {
    const data = {
      invoice: this.invoice,
      currencies: this.currencies$
    };
    const dialogRef = this.dialogHandlerService.openDialog(ChangeCurrencyComponent, data, {
      height: '350px',
      width: '30%',
      panelClass: 'custom-dialog-container',
      position: {
        top: '10%',
        left: '35%'
      },
    });

    dialogRef.componentInstance.changeCurrency.pipe(
      takeUntil(this.unsubscribe),
      tap((currency: InvoiceCurrency) => {
        this.oldCurrencyRate = this.invoice.currency.currencyRate;
        this.invoice = {...this.invoice, currency};
        this.store$.dispatch(new ChangeCurrencyAction(this.invoice));
      })
    ).subscribe();
  }

  checkSavedOrNot() {
    return this.invoice?.total !== +(this.totalsAfterTax[this.totalsAfterTax.length - 1])?.toFixed(2);
  }

  onChangeSVat($event: MatCheckboxChange) {
    if ($event.checked) {
      this.suspendedVat = true;
      this.invoice = {...this.invoice, suspendedVat: true};
    } else {
      this.suspendedVat = false;
      this.invoice = {...this.invoice, suspendedVat: false};
    }
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
    this.calculateTax();
  }

  calculateSVat() {
    const vat = this.invoice.taxes.filter(tax => tax.description === 'Vat');
    return (this.calculateSubTotal() / 100) * vat[0]?.value;
  }


  addPaymentTerm($event: { paymentTerm: PaymentTerm; isValidPaymentTerm: boolean }) {
    if ($event.isValidPaymentTerm) {
      const invoicePaymentTermStr = this.calculateRatesService
        .generatePaymentTermString($event.paymentTerm, this.invoice.currency, this.getTotal());
      this.invoice = {...this.invoice, invoicePaymentTerm: $event.paymentTerm, invoicePaymentTermStr};
      this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
    }
  }

  toggleLoads() {
    this.toggled = !this.toggled;
    this.invoice = {...this.invoice, loadsExpandState: this.toggled};
    this.store$.dispatch(new UpdateInvoiceAction(this.invoice));
  }
}
