import { Directive, Input, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: '[hmtRestrictInputDecimalPlaces]'
})
export class RestrictDecimalPlacesDirective {
  @Input() decimalPlaces: number = 0; // default set to integer

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    if (this.decimalPlaces === 0) return ;

    const inputValue: string = event.target.value;
    const regex = new RegExp(`^[0-9]+(\.[0-9]{1,${this.decimalPlaces}})?$`);

    if (!regex.test(inputValue)) {
      event.target.value = inputValue.slice(0, -1); // Remove extra chars
    }

  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Prevent typing of the dot character if decimalPlaces is not specified
    if (this.decimalPlaces === 0 && event.key === '.') {
      event.preventDefault();
    }
  }

}
