import {environment} from '@env/environment';
import { EntityMap } from '@shared/models/types';

export const BASE = environment.haulmatic.baseUrl;
// const ZUUL = `${BASE}/api-gateway`;
export const ZUUL = environment.haulmatic.apiGateway;
const VERSION = 'v2';
const SCHEDULE = `${ZUUL}/schedule/api`;
const AUTH = `${ZUUL}/auth`;
const JOB_FILE = `${ZUUL}/api/jobfile`;
const FLEET_MANAGER = `${ZUUL}/api/org/fleet-manager`; // TODO: change to correct path // TODO: change to correct path
const CONTROL_TOWER = `${ZUUL}/control-tower`; // TODO change using Navigation - Schedule and JPM in same Application
const JPM = `${ZUUL}/api/jpm`;
const S3 = `${ZUUL}/api/jpm/s3`;
const YOU_ARE_HERE = `${ZUUL}/api/pdf`;
const GATEWAY = `${ZUUL}/api/gateway`;
const QUOTATION = `${ZUUL}/api/fleetmanager/quotation`;
const RATE_CARD = `${ZUUL}/api/ratesheet`;
const SMART_CONTRACT = `${ZUUL}/api/smartcontract`;
const FRONTEND_V4 = BASE;
const FRONTEND_MASTER_DATA_V8 = `${BASE}/job-master`;
const FM_GPS = `${ZUUL}/api/telematics`;
const FILE_HANDLER = `${ZUUL}/api/file-handler`;
const WORKFLOW = `${ZUUL}/api/dynamicworkflow`;
const MASTER_DATA = `${ZUUL}/api/masterdata`;
const INVOICE = `${ZUUL}/api/invoice`;
const LOCATIONS = `${ZUUL}/locations`;
const ORGANIZATION = `${ZUUL}/api/organization-management`;
const LOAD_MANIFESTS = `${ZUUL}/api/reports/v1/load-manifest`;
const API_MANAGEMENT = `${ZUUL}/api-management`;
const API_GATEWAY = `${ZUUL}`;
export const GRAPHQL = `${ZUUL}/api/graphql`;
export const INVOICE_GRAPHQL = `${ZUUL}/api/invoice`;
export const REPORT_GRAPHQL = `${ZUUL}/api/report`;
// export const GRAPHQL_COMMON = `${ZUUL}/api/graphql-common`;
export const GRAPHQL_COMMON = `${ZUUL}/api/graphql-common`;
export const FILE_CONVERTER = `${ZUUL}/api/file-converter`;
export const NOTIFICATION = `${ZUUL}/api/v1/notifications`;

type StringFormatter = (urlParams: string[], queryParams?: EntityMap<string, string | number | boolean>) => string;
export const formatter = (apiString: string): { format: StringFormatter } => {
  const format = (urlParams: string[]): string => {
    // process url params
    let url = urlParams.reduce((prev: string, param: string, index: number) => {
      prev = prev.replace(`{${index}}`, param);
      return prev;
    }, apiString);
    // TODO: process query params map

    return url;
  };
  return {format};
};
export const API = {
  auth: {
    getToken: `${AUTH}/users/login`,
    refreshToken: `${AUTH}/users/refresh`,
    getUserByUserId: formatter(`${AUTH}/identity/{0}`),
    getTenants: `${AUTH}/tenant-details`,
    resetPasswordInit: `${AUTH}/users/reset-password/init`,
    resetPassword: `${AUTH}/users/reset-password/reset`,
  },
  schedule: {
    getContainerSchedules: formatter(`${SCHEDULE}/container/${VERSION}/{0}`),
    getContainerSchedule: formatter(`${SCHEDULE}/container/${VERSION}/{0}/{1}`),
    saveResourceUpdates: `${SCHEDULE}/container/${VERSION}/saveScheduleResources`,
    getResources: `${SCHEDULE}/resources/${VERSION}/findAvailableOrganizationResources/`,
    getResourceToContainerSchedules: `${SCHEDULE}/resources/${VERSION}/resourceToContainerSchedule`,
    getMostAppropriateResourceToContainerSchedules: `${SCHEDULE}/resources/${VERSION}/load-resource-grid`,
    notify: `${SCHEDULE}/container/${VERSION}/notify`,
    unAssign: `${SCHEDULE}/container/v2/un-assign`,
    splitAll: `${SCHEDULE}/container/${VERSION}/splitAll`,
    getExternalVehicles: `${SCHEDULE}/resources/${VERSION}/load-external-vehicles`,
    autoAssignContainer: formatter(`${SCHEDULE}/container/${VERSION}/auto-re-assign-resources/{0}/{1}/{2}?legOrder={3}`),
    updateLegsDates: formatter(`${SCHEDULE}/container/${VERSION}/orgId/{0}/jobRefId/{1}/containerId/{2}/legs/dates`),
    reScheduleCheck: `${SCHEDULE}/resources/${VERSION}/re-schedule/availability-check`,
    findResourceViewLogsByLeg: formatter(`${SCHEDULE}/resources/${VERSION}/resources-history/job/{0}/container/{1}/leg/{2}`),
    saveResourcePool: `${SCHEDULE}/resource-pool/`,
    getResourcePoolByJobRefIdAndContainerId: formatter(`${SCHEDULE}/resource-pool/org/{0}/job/{1}/container/{2}`),
    initResourcePool: formatter(`${SCHEDULE}/resource-pool/init-pool/org/{0}/job/{1}`),
    removePool: formatter(`${SCHEDULE}/resource-pool/org/{0}/job/{1}/pool/{2}`),
    getPoolsByJobRefId: formatter(`${SCHEDULE}/resource-pool/org/{0}/job/{1}`),
    getAvailableContainers: formatter(`${SCHEDULE}/resource-pool/available-containers/org/{0}/job/{1}`),
    addContainersToPool: `${SCHEDULE}/resource-pool/addContainersToPool`,
    removeContainerFromPool: formatter(`${SCHEDULE}/resource-pool/org/{0}/job/{1}/container/{2}`),
    addTeamToPool: `${SCHEDULE}/resource-pool/saveTeamsToPool`,
    removeTeam: formatter(`${SCHEDULE}/resource-pool/org/{0}/job/{1}/pool/{2}/team/{3}`),
    notifyPool: formatter(`${SCHEDULE}/resource-pool/notify-pool/org/{0}/job/{1}/pool/{2}`),
    removeAllContainer: formatter(`${SCHEDULE}/resource-pool/org/{0}/job/{1}/pool/{2}/container/all`),
    getPoolContainerCount: formatter(`${SCHEDULE}/resource-pool/container-count/org/{0}/job/{1}`),
    selectAllContainers: `${SCHEDULE}/resource-pool/addContainersToPool/select-all`,
    getAllResourcePoolTemplates: formatter(`${SCHEDULE}/resource-pool/team-template/all/org/{0}`),
    addTeamsTemplate: `${SCHEDULE}/resource-pool/template/save-team-template`,
    getFuResourceDetails: formatter(`${SCHEDULE}/resources/v2/fleet-utilization/from/{0}/to/{1}/type/{2}`)
  },
  jobFile: {
    createJobFile: `${JOB_FILE}/init-jobFile`,
    getJobFiles: `${JOB_FILE}/ready-to-schedule`,
    getJobFileSummaries: `${JOB_FILE}/ready-to-schedule-jobs`,
    getJobFilesByJobGroupId: formatter(`${JOB_FILE}/job-files?pageNum={0}&pageSize={1}`),
    getAllJobFiles: formatter(`${JOB_FILE}/org/{0}/find`),
    filterJobFiles: `${JOB_FILE}/filter`,
    getJobFileByOrgIdAndJobRefId: formatter(`${JOB_FILE}/{0}/{1}`),
    getJobFileByJobRefId: formatter(`${JOB_FILE}/jobs/{0}`),
    getWorkOrdersDefinitionsByJpmIdAndLegId: formatter(`${JOB_FILE}/monitoring/v2/work-orders/definitions/jpm/{0}/leg/{1}/find`),
    getOptionalWorkOrdersDefinitions: formatter(`${JOB_FILE}/monitoring/v2/work-orders/definitions/activity/{0}/work-order/{1}?orgId={2}&jobRefId={3}`),
    addAdditionalWorkOrders: formatter(`${JOB_FILE}/monitoring/v2/work-orders/previous-work-order/{0}/add`),
    getWorkOrdersByJpmIdAndLegId: formatter(`${JOB_FILE}/monitoring/v2/work-orders/jpm/{0}/leg/{1}/find`),
    getWorkOrdersByJpmId: formatter(`${JOB_FILE}/monitoring/v2/work-orders/jpm/{0}/find`),
    getWorkOrdersByWorkOrderId: formatter(`${JOB_FILE}/monitoring/v2//work-orders/work-order/{0}/find`),
    deleteWorkOrdersByWorkOrderId: formatter(`${JOB_FILE}/monitoring/v2/work-orders/work-order/{0}`),
    getWorkOrderMonitoringActivitiesByOrdIdAndJobRefIdAndContainerId: formatter(`${JOB_FILE}/monitoring/v2/activities/find/{0}/{1}/{2}`),
    getJpmCompletionPercentage: formatter(`${JOB_FILE}/monitoring/v2/jpm-completion-percentage/org/{0}/job/{1}`),
    saveSpecialInstructionOfActivity: formatter(`${JOB_FILE}/monitoring/v2/work-orders/{0}/special-instructions`),
    getActivityDocumentsForJpm: formatter(`${JOB_FILE}/monitoring/v2/activities/jpm/{0}/documents`),
    createInitContainer: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/confirm`),
    saveInitContainer: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/save-containers`),
    getContainer: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/add-container`),
    getContainerByOrder: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/order/{2}/add-container`),
    activityList: `${JOB_FILE}/monitoring/v2/activities`,
    createActivity: `${JOB_FILE}/monitoring/v2/activities/activity/create`,
    moveActivity: `${JOB_FILE}/monitoring/v2/activities/activity/drag-drop`,
    createUnPlannedStopActivity: `${JOB_FILE}/monitoring/v2/activities/activity/unplanned-stop-to-activity`,
    removeActivity: formatter(`${JOB_FILE}/monitoring/v2/activities/activity/{0}/by/{1}`),
    modifyActivity: `${JOB_FILE}/monitoring/v2/activities/activity/modify`,
    modifyLegDistance: formatter(`${JOB_FILE}/monitoring/v2/activities/leg-distance/modify/jpm-id/{0}/leg-id/{1}`),
    modifyActivityBilling: formatter(`${JOB_FILE}/monitoring/v2/activities/{0}/set-billing`),
    getWorkOrderDefinition: formatter(`${JOB_FILE}/monitoring/v2/work-orders/definitions/find/{0}`),
    getWorkOrderDefinitionsOfAdditionalActivitiesByWorkFlowId: formatter(`${JOB_FILE}/monitoring/v2/work-orders/definitions/additional-services/workFlowId/{0}/find`),
    assignAndSchedule: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/order/{2}/assign-schedule`),
    generateInitLegs: `${JOB_FILE}/legs/generate/dry`,
    terminateJob: formatter(`${JOB_FILE}/terminatejobfile/orgId/{0}/jobRefId/{1}`),
    assignSupervisorToQuotation: `${JOB_FILE}/quotation/work-order/assign-supervisor-to-quotation`,
    assignSupervisorToGenerateQuotation: formatter(`${JOB_FILE}/quotation/work-order/org/{0}/jobs/{1}/order/{2}/supervisor/{3}/assign-supervisor-to-generate-quotation`),
    sendForApproval: formatter(`${JOB_FILE}/wizard/work-order/org/{0}/jobs/{1}/order/{2}/send-for-approval-container-run-time`),
    sendQuotationToFleetManager: formatter(`${JOB_FILE}/quotation/work-order/org/{0}/jobs/{1}/order/{2}/send-quotation-to-fleetManager`),
    getContainerTemplates: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/service-sequence-template/search?searchKey={2}`),
    getContainerTemplatesByOperation: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/operation/{2}/service-sequence-template/search?searchKey={3}`),
    removeTemplate: formatter(`${JOB_FILE}/service-sequence-template/template/{0}/remove`),
    contractedRate: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/order/{2}/apply-contracted-rate`),
    getWorkOrderDefinitionList: `${JOB_FILE}/monitoring/v2/work-orders/definitions/find`,
    saveServiceSequenceTemplate: `${JOB_FILE}/service-sequence-template`,
    updateServiceSequenceTemplate: `${JOB_FILE}/service-sequence-template`,
    downloadJobSummary: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/order/{2}/pdf/job-summary`),
    generateJobSummaryAsync: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/order/{2}/pdf/job-summary/async`),
    generateCollaboratorsAsync: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/order/{2}/collaborators/generate/async`),
    generateJobSummary: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/order/{2}/pdf/job-summary/generate`),
    getWorkOrderDefinitionListWithChildren: `${JOB_FILE}/monitoring/v2/work-orders/definitions/find/with-children`,
    applyServiceSequenceTemplate: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/sst/{3}/apply`),
    updateService: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/services/service/update`),
    addService: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/index/{3}/services/service/add`),
    generateDryServiceByActivityId: formatter(`${JOB_FILE}/monitoring/v2/services/by-activity/{0}/billingActivated/{1}/billingIndicator/{2}/generate/dry?jobRefId={3}&containerId={4}`),
    removeService: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/service/{3}/remove`),
    duplicateContainer: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/duplicate`),
    saveContainerBulk: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/order/{2}/bulk`),
    addNewContainer: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/container/add`),
    removeContainer: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/remove`),
    removeAllContainers: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/order/{2}/containers/remove`),
    updateContainer: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/update`),
    updateOrdersData: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/shipment-orders`),
    updateContainerByUseExpressWayOption: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/express-ways/{3}`),
    updateMapDistanceByUseExpressWayOption: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/express-ways/{3}/map-distance`),
    optimizeOrderMovedContainers: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/fromContainer/{2}/toContainer/{3}/optimize-containers`),
    saveContainerData: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/save-container`),
    updateContainerWhenAddWayPoint: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/update-container-add-wayPoint`),
    calculateRouteDetails: formatter(`${JOB_FILE}/locations/from/location/{0}/to/location/{1}/calculated-route`),
    retrieveCategorizedLocationNames: formatter(`${JOB_FILE}/workflow/org/{0}/jobs/{1}/locations/categorize`),
    getJobSummary: formatter(`${JOB_FILE}/org/{0}/job/{1}/order/{2}/job-summary/generate`),
    finalizeJobFile: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/order/{2}/generate-container-route-images`),
    getJobSummaryPdfPreSigned: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/job-summary/pdf/pre-signed`),
    gatConfig: formatter(`${JOB_FILE}/config/job-summary/{0}/{1}`),
    jobSendForApproval: formatter(`${JOB_FILE}/wizard/work-order/org/{0}/jobs/{1}/order/{2}/job-send-for-approval`),
    loadWorkflowDefinition: formatter(`${JOB_FILE}/workflow/definitions/definition/v2/{0}`),
    updateCustomsChannel: formatter(`${JOB_FILE}/org/{0}/job/{1}/container/{2}/customsChannel/{3}/update-customs-channel`),
    updateChannelAndRemoveService: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/service/{3}/channel/{4}/update-customs-channel-and-remove-service`),
    updateChannelAndAddDryService: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/index/{3}/activity/{4}/channel/{5}/update-customs-channel-and-add-dry-service?operationId={6}&workflowId={7}`),
    saveWizardRowData: formatter(`${JOB_FILE}/org/{0}/jobs/{1}`),
    saveWizardVehicleCapacities: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/vehicle-capacities`),
    saveWizardDistributionWorkflowData: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/distribution-workflow-data`),
    downloadPackingList: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/shipment-orders/download`),
    downloadPackingListZip: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/shipment-orders/download`),
    getJobsForTaskFeed: formatter(`${JOB_FILE}/task-feed/org/{0}/assignedType/{1}/userId/{2}/state/{3}/module/{4}/pageNum/{5}/pageSize/{6}/searchKey/{7}`),
    getTaskFeedJobCount: formatter(`${JOB_FILE}/task-feed/org/{0}/userId/{1}/count`),
    getOngoingJobs: formatter(`${JOB_FILE}/task-feed/ongoingJobs/org/{0}/userId/{1}/pageNum/{2}/pageSize/{3}/searchKey/{4}`),
    getTaskFeedJobByJobRefId: formatter(`${JOB_FILE}/job-files/inprogress-workorders/org/{0}/job/{1}`),
    // job groups
    createJobGroup: `${JOB_FILE}/job-groups`,
    getJobGroups: formatter(`${JOB_FILE}/job-groups/orgId/{0}/pageNum/{1}/pageSize/{2}/searchKey/{3}`),
    uploadWizardAttachments: `${JOB_FILE}`,
    removeWizardAttachment: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/attachment/{2}/remove-document`),
    moveJobFileToGroup: `${JOB_FILE}/job-groups/move-job-to`,
    inviteCollaborators: `${JOB_FILE}/invite-collaborators`,
    removeCollaborator: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/remove-collaborator/{2}`),
    // collaborators
    getJobVerticalCollaboratorsOfContainer: formatter(`${JOB_FILE}/collaborators/org/{0}/jobs/{1}/container/{2}`),
    inviteCollaboratorsOfJob: `${JOB_FILE}/collaborators/assign/persons`,
    saveJobVerticalCollab: `${JOB_FILE}/collaborators/collaborator`,
    editCollaboratorLoads: `${JOB_FILE}/collaborators/person/loads`,
    deleteJobVerticalCollab: formatter(`${JOB_FILE}/collaborators/jobVertical/{0}/person/{1}`),
    deleteLoadOfCollab: formatter(`${JOB_FILE}/collaborators/jobVertical/{0}/jpm/{1}/container/{2}/person/{3}`),
    setBillingStartAndEnd: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/services/{3}/set-billing`),
    loadShipmentOrderSpecs: formatter(`${JOB_FILE}/shipment-order-spec/orgId/{0}/pageNum/{1}/pageSize/{2}`),
    filterShipmentOrderSpecs: `${JOB_FILE}/shipment-order-spec/filter`,
    uploadShipmentOrderExcel: formatter(`${JOB_FILE}/shipment-order-spec/org/{0}/import-excel`),
    addShipmentOrdersToJob: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/add-shipment-orders`),
    deleteShipmentOrder: formatter(`${JOB_FILE}/shipment-order-spec/{0}`),
    deleteShipmentOrderFromJobFile: formatter(`${JOB_FILE}/{0}/shipmentOrderSpec/{1}/remove-shipment-order-spec`),
    deleteAllShipmentOrdersFromJobFile: formatter(`${JOB_FILE}/{0}/shipmentOrderSpec/remove/all`),
    reArrangeLocationOrder: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/prev/{3}/curr/{4}/services/service/reArrange`),
    reTryDeferredActivity: formatter(`${JOB_FILE}/monitoring/v2/activities/activity/{0}/retry-deferred`),
    deferActivity: `${JOB_FILE}/monitoring/v2/activities/activity/mark-as-deferred`,
    markAsDeliveryFailed: formatter(`${JOB_FILE}/monitoring/v2/activities/activity/{0}/delivery-failed`),
    saveOtherReferenceNumber: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/locations/{3}/services/{4}/{5}/other-ref-numbers/{6}`),
    saveOtherReferenceNumbers: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/locations/{3}/services/{4}/{5}/other-ref-numbers`),
    saveOrderReferenceNumber: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/locations/{3}/services/{4}/{5}/order-numbers/{6}`),
    saveOrderReferenceNumbers: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/locations/{3}/services/{4}/{5}/order-numbers`),
    deleteOtherReferenceNumber: formatter(`${JOB_FILE}/org/{0}/jobs/{1}/containers/{2}/locations/{3}/services/{4}/{5}/other-ref-numbers/{6}`),
    getOrderRefNumbers: formatter(`${JOB_FILE}/org/jobs/containers/order-numbers/{0}`),
  },
  fleetManager: {
    createFuelRequest: formatter(`${FLEET_MANAGER}/fuel/requests/request/org/{0}/vehicle/{1}`),
    createAndApproveFuelRequest: formatter(`${FLEET_MANAGER}/fuel/requests/request/org/{0}/vehicle/{1}/create-and-approve`),
    getFuelRequest: formatter(`${FLEET_MANAGER}/fuel/requests/org/{0}/state/{1}`),
    getFuelRequestById: formatter(`${FLEET_MANAGER}/fuel/requests/{0}`),
    moveToPendingFuelRequest: formatter(`${FLEET_MANAGER}/fuel/requests/{0}/move/PENDING`),
    rejectFuelRequest: formatter(`${FLEET_MANAGER}/fuel/requests/{0}/reject`),
    approveFuelRequest: formatter(`${FLEET_MANAGER}/fuel/requests/{0}/approve`),
    completeFuelRequest: formatter(`${FLEET_MANAGER}/fuel/requests/{0}/complete`),

    getResources: formatter(`${FLEET_MANAGER}/resources/{0}`),
    getOrgPartnerLocations: formatter(`${FLEET_MANAGER}/partner/partnersWithGeoByPartnerId/org/{0}/partnerId/{1}/organizationFunction/{2}`),
    getOrgPartnerByOrganizationFunction: formatter((`${FLEET_MANAGER}/partner/org/{0}/partners/organizationFunction/{1}`)),

    getDefaultTeam: formatter(`${ORGANIZATION}/vehicles/managed-by`),
    getOrgPersonsByIds: `${ORGANIZATION}/org-user/org-person-ids`,
    getAllVehiclesForAGivenOrganization: formatter(`${ORGANIZATION}/vehicles/managed-by`),
    getAllDriversForAGivenOrganization: formatter(`${ORGANIZATION}/org-users/scopes/{0}`),
    getCustomerByCustomerId: formatter(`${ORGANIZATION}/organizations/{0}`),
    getPersonInRequest: `${ORGANIZATION}/org-user/org-person-ids`,
    getPersonsByType: formatter(`${ORGANIZATION}/org-users/scopes/{0}`),
    getPersonsByScopeAndReportsToIn: formatter(`${ORGANIZATION}/org-user/scopes/{0}/ReportsToIn/{1}`),
    getAllVehiclesManagedBy: `${ORGANIZATION}/vehicles/managed-by`,
  },
  quotation: {
    getQuotationByOrgIdAndJobRefId: formatter(`${QUOTATION}/{0}/byjobref/{1}`),
    getQuotationByOrgIdAndJobRefIdAndOrder: formatter(`${QUOTATION}/{0}/{1}/job-file-quotations/order/{2}`),
    updateTheOffer: formatter(`${QUOTATION}/org/{0}/jobs/{1}/job-quotations/{2}/update-offer`),
    getTemporaryQuotationForContainer: formatter(`${QUOTATION}/org/{0}/jobs/{1}/rateCards/{2}/temporary`),
    updateQuotation: formatter(`${QUOTATION}/org/{0}/jobs/{1}/rateCards/{2}`),
    finalizeQuotation: formatter(`${QUOTATION}/org/{0}/jobs/{1}/{2}/finalize`),
    getTemporaryFinalizeQuotation: formatter(`${QUOTATION}/org/{0}/jobs/{1}/{2}/finalize/temporary`),
    generateContractForQuotation: formatter(`${QUOTATION}/org/{0}/jobs/{1}/quotation/{2}/preparedBy/{3}/generate-contract`),
    getJobWorkflowDataByOrgIdAndJobRefId: formatter(`${QUOTATION}/workflowpersist/getall/{0}/{1}`),
    getPdfUrl: formatter(`${QUOTATION}/org/{0}/jobs/{1}/job-quotations/{2}/{3}`),
    saveOffer: formatter(`${QUOTATION}/org/{0}/jobs/{1}/job-quotations/{2}/offer`),
    generatePdfUrlV2: formatter(`${QUOTATION}/v2/org/{0}/jobs/{1}/order/{2}/pdf/generate-and-save`),
    generateSavePDF: formatter(`${QUOTATION}/v2/org/{0}/jobs/{1}/order/{2}/pdf/save`),
    acceptOffer: formatter(`${QUOTATION}/org/{0}/jobs/{1}/job-quotations/{2}/accept`),
    saveInitQuotation: formatter(`${QUOTATION}/org/{0}/jobs/{1}/create`),
    assignSupervisorToQuotation: formatter(`${QUOTATION}/org/{0}/jobs/{1}/job-quotations/{2}/assign-supervisor-to-quotation`),
    getQuotationPDFUrl: formatter(`${QUOTATION}/v2/org/{0}/jobs/{1}/order/{2}`),
    getQuotationPDFUrlWithCurrency: formatter(`${QUOTATION}/v2/org/{0}/jobs/{1}/order/{2}/isDefaultCurrency/{3}/pdf`),
    getQuotationPDFPreSignedUrlByRefId: formatter(`${QUOTATION}/v2/org/{0}/jobs/{1}/ref/{2}/pdf/pre-signed`)
  },
  APP_ROUTE: {
    getRoute: `${FRONTEND_V4}`,
    getRoutePostAbans: `${FRONTEND_V4}`,
    getRouteMasterData: `${FRONTEND_MASTER_DATA_V8}`
  },
  jpm: {
    makeAsDoneTask: `${JPM}/task/complete`,
    reportIncident: `${JPM}/incidents/incident/report`,
    getReportIncidents: formatter(`${JPM}/incidents/jpm/{0}/leg/{1}/activity/{2}`),
    updateIncidentAction: formatter(`${JPM}/incidents/incident/{0}/action/{1}/previous-work-order/{2}`),
    getJpmById: formatter(`${JPM}/{0}`),
    getJpms: formatter(`${JPM}/{0}/byjobref/{1}`),
    getAllowedJpmByJpmIdAndPrincipalId: formatter(`${JPM}/jpms/summary/{0}`),
    getAllowedJpmByJobRefIdAndContainerId: formatter(`${JPM}/jpms/by-container/{0}/{1}`),
    getJpmByOrgIdAndJobRefIdAndContainerId: formatter(`${JPM}/{0}/jobRefId/{1}/containerId/{2}`),
    terminateJob: `${JPM}/terminate-job`,
    getActivityAuditLog: formatter(`${JPM}/jpms/{0}/logs`),
    deleteContainer: formatter(`${JPM}/remove-container/org/{0}/job/{1}/container/{2}/prepared-by/{3}`),
    createRuntimeCost: formatter(`${JPM}/{0}/expenses/expense/create-and-confirm`),
    removeRuntimeCost: formatter(`${JPM}/{0}/costs/{1}`),
    updateRuntimeCost: formatter(`${JPM}/{0}/costs`),
    confirmExpense: formatter(`${JPM}/{0}/expenses/expense/confirm`),
    rejectExpense: formatter(`${JPM}/{0}/expenses/{1}/reject`),
    removeExpense: formatter(`${JPM}/{0}/expenses/{1}`),
    getAllIncidents: formatter(`${JPM}/incidents/jpm/{0}`),
    deleteIncident: formatter(`${JPM}/incidents/{0}`),
    modifyJobDistance: formatter(`${JPM}/task/complete/modify/jpm/{0}/job-distance`),
    terminateContainer: formatter(`${JPM}/org/{0}/job/{1}/container/{2}/terminate-container`),
    getJPMDocuments: formatter(`${JPM}/org/{0}/jobs/{1}/documents`),
    getDropDownValues: `${JPM}`,
    downloadRunSheet: formatter(`${JPM}/job-reports/org/{0}/jobs/{1}/jpms/{2}/run-sheet/pdf`),
    getInternalContainers: formatter(`${JPM}/org/{0}/jobs/{1}/internal-containers`),
    getSubContractContainers: formatter(`${JPM}/org/{0}/jobs/{1}/sub-contractor/{2}/sub-contract-containers`),
    getSubContractedSummaries: formatter(`${JPM}/org/{0}/jobs/{1}/sub-contracted-detail-list`),
    getPendingContainers: formatter(`${JPM}/org/{0}/jobs/{1}/pending-containers`),
    getContainersStatus: formatter(`${JPM}/org/{0}/jobs/{1}/containers-status`),
    saveSubContractDetails: `${JPM}/task/sub-contract-details`,
    schedulePoolJob: `${JPM}/task/schedule-repo-removal`,
    markAsComplete: formatter(`${JPM}/org/{0}/job/{1}/mark-as-complete`),
    markAsUnDone: formatter(`${JPM}/task/complete/work-orders/{0}/mark-as-undone`),
    markAsCancel: formatter(`${JPM}/task/complete/work-orders/{0}/mark-as-cancel`),
    markAsInvoke: formatter(`${JPM}/task/complete/work-orders/{0}/mark-as-invoke`),
    setWorkOrderGeoCoordinate: formatter(`${JPM}/task/complete/jpm/{0}/work-order/{1}/vehicle/{2}/set-geo-coordinate-from-web`),
    getJobCompletionStatus: formatter(`${JPM}/org/{0}/jobs/{1}/job-completion-status`),
    coupleContainer: `${JPM}/container/couple`,
    deCoupleContainer: `${JPM}/container/deCouple`,
    getAllAllowedJPMs: `${JPM}/collaborators/jpms`,
    loadSharedJobs: formatter(`${JPM}/collaborator/jobs`),
    loadSharedJobCounts: formatter(`${JPM}/collaborator/jobs/count`),
    getLoadsOfJob: formatter(`${JPM}/org/{0}/jobs/{1}/container/list`),
    filterLiveMapJobs: `${JPM}/collaborator/live-map/jobs`,
    getCompletedJpmDistances: formatter(`${JPM}/jobs/{0}/completed-loads-distances`),
    updateCompletedJpmDistances: formatter(`${JPM}/jobs/{0}/container/{1}/update-jpm-distances`)
  },
  controlTower: {
    jobProgress: formatter(`${CONTROL_TOWER}/?jobRefId={0}&containerId={1}`),
    terminateJob: `${CONTROL_TOWER}/terminate-job`,
  },
  s3: {
    uploadFile: formatter(`${S3}/upload-files/{0}/{1}/{2}/{3}/{4}`),
    getFile: `${S3}/getSpecificFile`,
    getDocuments: formatter(`${S3}/files/get-documents/{0}`),
    getPreSignedUrls: formatter(`${S3}/pre-signed-urls/jpm/{0}`)
  },
  youAreHere: {
    getPDF: {
      getDocumentLink: formatter(`${YOU_ARE_HERE}/url`)
    }
  },
  gateway: {
    getLocations: `${GATEWAY}/locations`,
    getLocationsByType: formatter(`${GATEWAY}/locations/{0}`)
  },
  rateCard: {
    getRateCards: `${RATE_CARD}/ratecards`,
    getRateCardsForOperation: `${RATE_CARD}/ratecards/get-data-operation`,
    getRateCardsByFilters: formatter(`${RATE_CARD}/ratecards/orgId/{0}/pageNo/{1}/pageSize/{2}/by-filters`),
    getRateCardsByFilter: formatter(`${RATE_CARD}/ratecards/orgId/{0}/by-filters`),
    getCost: formatter(`${RATE_CARD}/costs/search?keyword={0}`),
    getAllTiers: formatter(`${RATE_CARD}/tier/getall/{0}`),
    getPaginateRateTiers: formatter(`${RATE_CARD}/tier/get-paginate-rate-tiers/{0}`),
    saveTier: `${RATE_CARD}/tier`,
    updateTier: `${RATE_CARD}/tier`,
    removeTier: formatter(`${RATE_CARD}/tier/{0}`),
    saveRateCard: `${RATE_CARD}/ratecard`,
    deleteRateCard: formatter(`${RATE_CARD}/ratecard/{0}`),
    setExchangeRate: formatter(`${RATE_CARD}/rateCard/{0}/exchangeRate/{1}/set-exchange-rate`),
    getRateCardsByIds: `${RATE_CARD}/ratecards/by-ids`,
    findCommitmentSuppliers: formatter(`${RATE_CARD}/ratecard/org/{0}/contractType/{1}/from/{2}/to/{3}/organization_ids`),
    saveOperationDistanceMatrix: `${RATE_CARD}/operation/distance-matrix-template`,
    getOperationDistanceMatrix: formatter(`${RATE_CARD}/operation/distance-matrix-template/{0}/orgId/{1}`),
  },
  smartContract: {
    generateContract: formatter(`${SMART_CONTRACT}/smartContractPdf/byorg/{0}/jobref/{1}/acceptedQuotationId/{2}/preparedBy/{3}`),
    getAllContracts: formatter(`${SMART_CONTRACT}/byorg/{0}/jobref/{1}`),
    getSmartContractVersions: formatter(`${SMART_CONTRACT}/{0}/{1}/versions`),
    getLatestContractView: formatter(`${SMART_CONTRACT}/v2/org/{0}/jobs/{1}/versions/latest/view`),
    getSpecificContractView: formatter(`${SMART_CONTRACT}/v2/org/{0}/jobs/{1}/versions/{2}/view`),
    getDownloadContractPDF: formatter(`${SMART_CONTRACT}/v2/org/{0}/jobs/{1}/versions/{2}/pdf/pre-signed-url`),
    getContractPreSignedFile: formatter(`${SMART_CONTRACT}/v2/org/{0}/jobs/{1}/versions/{2}/pdf/pre-signed`),
    generateAndSavePDF: formatter(`${SMART_CONTRACT}/v2/org/{0}/jobs/{1}/versions/{2}/pdf/generate/save`),
  },
  assets: {
    getHSCodes: './assets/json-data/hsCodes.json',
    getContainerTypesAndSizes: './assets/json-data/containerTypeAndSize.json',
    getInvoiceCosts: './assets/json-data/invoice-costs.json',
    getConfigRelevantToWorkFlow: formatter(`./assets/json-data/add-container-configurations/{0}.json`),
    getErrorMessage: './assets/json-data/error-messages.json',
    getCostTypeList: './assets/json-data/invoice-costs.json'
  },
  google: {
    apiUrl: 'https://maps.googleapis.com/maps/api/staticmap?size=150x100&scale=2&maptype=roadmap&style=feature:all&path=weight:1%7Ccolor:0xff0000ff%7Cgeodesic:true%7Cenc:'
  },
  fmGPS: {
    getGpsInfoListAboveLogDate: formatter(`${FM_GPS}/trips/v1/jpm/{0}/logDate/{1}/`),
    getEncodedPathDataFromCompletedTrips: formatter(`${FM_GPS}/trips/v1/encodedTripPath/jpm/{0}/completed`),
    getEncodedPathDataFromOngoingTrip: formatter(`${FM_GPS}/trips/v1/encodedTripPath/jpm/{0}/currentDate/{1}/ongoing`),
    getEncodedPathsOfJPMsByJPMIds: `${FM_GPS}/trips/v1/jpms/encoded-trip-paths`,
    getUnplannedStops: formatter(`${FM_GPS}/trips/v1/jpm/{0}/unplanned-stops`),
    getSpeedsOfVehicle: formatter(`${FM_GPS}/vehicle/v1/{0}/startDate/{1}/currentDate/{2}/speeds`),
    getLastGpsInfo: formatter(`${FM_GPS}/trips/v1/lastGpsInfo/jpm/{0}/currentDate/{1}`),
    restartLocationServiceOfVehicle: formatter(`${FM_GPS}/vehicle/v1/{0}/restartLocationService`),
    getAllDevices: `${FM_GPS}/gps-devices/v1`,
    updateGpsDevice: `${FM_GPS}/gps-devices/v1/update`,
    updateDeviceMappingCache: formatter(`${FM_GPS}/gps-devices/v1/{0}/vehicle-mapping/data/refresh`),
    getGpsLogData: formatter(`${FM_GPS}/gps-devices/v1/{0}/gps-log-data`),
    getAllActiveDevices: `${FM_GPS}/gps-devices/v1/active-gps-device`,
    getAllRemovedDevices: `${FM_GPS}/gps-devices/v1/removed-gps-device`,
    getAllUnAssignedDevices: `${FM_GPS}/gps-devices/v1/unassigned-gps-device`,
    changeActiveStatusGpsDevice: formatter(`${FM_GPS}/gps-devices/v1/{0}/gps-change-status/device`),
    changeGpsDeviceAssignVehicle: formatter(`${FM_GPS}/gps-devices/v1/{0}/gps-change-assign/vehicle`),
    getEncodedPathByVehicle: formatter(`${FM_GPS}/trips/v1/encodedTripPath/jpm/{0}/vehicle/{1}/currentDate/{2}/ongoing-by-vehicle`),
  },
  fileHandelar: {
    thumbnail: `${FILE_HANDLER}/v1/files/thumbnail/default/pre-signed`,
    imageUrl: formatter(`${FILE_HANDLER}/v1/files/{0}/pre-signed`),
    fetchThumbnail: formatter(`${FILE_HANDLER}/v1/files/{0}/thumbnail/default/pre-signed`),
    fetchThumbnails: `${FILE_HANDLER}/v1/files/thumbnail/default/pre-signed`,
    uploadFile: formatter(`${FILE_HANDLER}/v1/files/file/org/{0}/path/{1}`),
    getPreSignedUrl: formatter(`${FILE_HANDLER}/v1/files/{0}/pre-signed`)
  },
  fileConverter: {
    getJobMasterExcel: `${FILE_CONVERTER}/v1/convert/jobmaster`
  },
  workflow: {
    // getWorkflowConfigByWorkflowId: formatter(`${WORKFLOW}/workflow/getall/{0}/{1}`),
    // getWorkflowData: formatter(`${WORKFLOW}/workflowpersist/getall/{0}/{1}`),
    getWorkFlowSteps: formatter(`${JOB_FILE}/workflow/steps/{0}`),
    getCustomsChannels: formatter(`${JOB_FILE}/workflow/{0}/customs-channel`)
  },
  masterData: {
    masterDataKPIExcelReportUrl: formatter(`${MASTER_DATA}/reports/excel/kpi/from/{0}/to/{1}/orgId/{2}`),
    getWorkFlowSteps: formatter(`${JOB_FILE}/workflow/steps/{0}`),
    getCustomsChannels: formatter(`${JOB_FILE}/workflow/{0}/customs-channel`)
  },
  invoice: {
    generateInvoice: formatter(`${INVOICE}/v2/org/{0}/job/{1}/user/{2}/pdf`),
    getInvoice: formatter(`${INVOICE}/v2/org/{0}/job/{1}`),
    downloadInvoicePdf: formatter(`${INVOICE}/v2/org/{0}/job/{1}/downloadInvoicePdf`)
  },
  locations: {
    saveOrganizationLocation: `${LOCATIONS}/`,
    getOrganizationLocation: formatter(`${LOCATIONS}/{0}`),
    // getOrganizationLocationsByOrg: formatter(`${LOCATIONS}`),
    deleteOrganizationLocation: formatter(`${LOCATIONS}/{0}`),
    getLocationDefinitionByParentLocationType: formatter(`${LOCATIONS}/definitions/by-type/{0}`),
    getLocationDefinitions: `${LOCATIONS}/definitions`,
    getLocationsByFilter: `${LOCATIONS}`,
    getLocationsForWizard: `${LOCATIONS}/wizard`,
    getLocationReferenceIdAvailability: `${LOCATIONS}/availability`
  },
  organization: {
    saveOrganization: `${ORGANIZATION}/organizations/`,
    bulkUpload: `${ORGANIZATION}/organizations/bulk-upload`,
    getOrganization: formatter(`${ORGANIZATION}/organizations/{0}`),
    getPartnerOrganization: `${ORGANIZATION}/organizations/`,
    getCommonOrganizations: `${ORGANIZATION}/organizations/common`,
    getPaginatedCommonOrganizations: `${ORGANIZATION}/organizations/common/paginate`,
    getOnBoardedOrganizations: `${ORGANIZATION}/organizations/on-boarded`,
    getPaginatedOnBoardedOrganizations: `${ORGANIZATION}/organizations/on-boarded/paginate`,
    getOrganizationsByType: formatter(`${ORGANIZATION}/organizations/orgType/{0}`),
    getPartnerOrganizations: `${ORGANIZATION}/organizations/partners`,
    getPartnerOrganizationsByOperations: `${ORGANIZATION}/organizations/partners/operations`,
    getPaginatedPartnerOrganizationsByOperations: `${ORGANIZATION}/organizations/partners/operations/paginate`,
    getPartnerOrgsByVerticals: `${ORGANIZATION}/organizations/partners/by-verticals`,
    getChildrenOrganizations: formatter(`${ORGANIZATION}/organizations/{0}/children`),
    getPaginatedChildrenOrganizations: formatter(`${ORGANIZATION}/organizations/{0}/children/paginate`),
    getAllOnBoardedAndPartnerOrgsByVertical: formatter(`${ORGANIZATION}/organizations/by-vertical/{0}`),
    getOrganizationsByVertical: formatter(`${ORGANIZATION}/organizations/managedBy/by-vertical/{0}`),
    deleteOrganizationLocation: formatter(`${ORGANIZATION}/organizations/{0}`),
    getVerticalInfos: `${ORGANIZATION}/organizations/vertical-info/`,
    saveHumanResource: `${ORGANIZATION}/users/`,
    updateOrganizationUser: `${ORGANIZATION}/org-users/`,
    getHumanResourcesByOrg: formatter(`${ORGANIZATION}/users/org/{0}`),
    getHumanResourcesByOrgAndScopes: formatter(`${ORGANIZATION}/users/org/{0}/scopes`),
    deleteHumanResourceOfOrganization: formatter(`${ORGANIZATION}/org-user/{0}`),
    getOrgsByTypeAndId: formatter(`${ORGANIZATION}/organizations/{0}/type/{1}`),
    getVehiclesByResourceTypeAndVehicleTypes: formatter(`${ORGANIZATION}/vehicles/searchForIds/managedType/{0}`),
    getVehiclesByOrg: `${ORGANIZATION}/vehicles/managed-by`,
    getVehiclesByManagingOrg: formatter(`${ORGANIZATION}/vehicles/managed-by-org/{0}`),
    getVehiclesByManagingOrgAndPartnersOrg: formatter(`${ORGANIZATION}/vehicles/managed-by-org-and-partners/{0}`),
    getVehiclesByManagingOrgFilter: formatter(`${ORGANIZATION}/vehicles/managed-by-org/{0}/filter`),
    getVehiclesByManagingOrgAndIds: formatter(`${ORGANIZATION}/vehicles/managed-by-org/{0}/vehicles/{1}`),
    saveVehicle: `${ORGANIZATION}/vehicles/`,
    deleteVehicle: formatter(`${ORGANIZATION}/vehicles/{0}`),
    getRolesByOrgId: formatter(`${ORGANIZATION}/roles/org/{0}`),
    createRole: `${ORGANIZATION}/roles/`,
    updateRole: `${ORGANIZATION}/roles/`,
    deleteRole: formatter(`${ORGANIZATION}/individual/{0}`),
    getOrgPartners: formatter(`${ORGANIZATION}/organizations/{0}/type/{1}`),
    OrgUsersByReportsToInAndScopesIn: formatter(`${ORGANIZATION}/org-user/scopes/{0}/ReportsToIn/{1}`),
    getOrgLogo: formatter(`${ORGANIZATION}/organizations/{0}/get-logo`),
    getOrgUserByScopes: formatter(`${ORGANIZATION}/users/scopes?pageNum={0}&pageSize={1}`),
    getOrgLogos: `${ORGANIZATION}/organizations/logos`,
    getOrgDetails: `${ORGANIZATION}/organizations/customers`,
    getCurrencies: `${ORGANIZATION}/countries/currencies`,
    getCountryById: formatter(`${ORGANIZATION}/countries/{0}`),
    getCountries: `${ORGANIZATION}/countries`,
    getHolidaysByCountryAndYear: formatter(`${ORGANIZATION}/countries/countryCode/{0}/year/{1}`),
    checkOrgShortNameAvailability: formatter(`${ORGANIZATION}/organizations/{0}/availability`),
    checkVehicleAvailability: formatter(`${ORGANIZATION}/vehicles/{0}/availability`),
    // TODO: refactor this API to and paginate
    getOrganizationsByOrgIdAndOrgType: formatter(`${ORGANIZATION}/organizations/get-related-organizations/all/{0}/organizationType/{1}`),
    getOrgPersonsOfVertical: formatter(`${ORGANIZATION}/users/org/{0}/scopes`),
    getCollabLogoMap: `${ORGANIZATION}/organizations/logos-map`,
    getOrgPersonsByIds: `${ORGANIZATION}/org-user/org-person-ids`,
    getLiveMapCustomers: formatter(`${ORGANIZATION}/organizations/customers/org/{0}/user/{1}`),
    getLiveMapServiceProviders: formatter(`${ORGANIZATION}/organizations/service-providers/org/{0}/user/{1}`),
    getOrganizationInvoiceLoadsLimit: formatter(`${ORGANIZATION}/organizations/{0}/invoice-loads-limit`),
    getOperations: `${ORGANIZATION}/operations/`,
    getOperationSpeedLimits: formatter(`${ORGANIZATION}/operations/{0}/geo-fence-speeds/all`),
    saveOperationSpeedLimits: formatter(`${ORGANIZATION}/operations/{0}/geo-fence-speeds`),
    deleteOperationSpeedLimits: formatter(`${ORGANIZATION}/operations/{0}/geo-fence-speeds/{1}/delete`),
    updateAndAddOperation: `${ORGANIZATION}/operations/`,
    getCurrentUserOperations: `${ORGANIZATION}/operations/current-user`,
    getVehicleCategories: `${ORGANIZATION}/meta-data/vehicle-category`,
    getOrgIdsByOrgType: `${ORGANIZATION}/organizations/wizard/related-org-ids`,
    getSharedVehicleData: formatter(`${ORGANIZATION}/live-maps/shared-org-infos/{0}`),
    getAllSharedVehicleData: formatter(`${ORGANIZATION}/live-maps/org/{0}/shared-org-infos`),
    saveSharedOrgData: `${ORGANIZATION}/live-maps/shared-org-info`,
    getShareableVehicleData: formatter(`${ORGANIZATION}/vehicles/internal-and-partner/{0}`),
    getUsersOfOrg: formatter(`${ORGANIZATION}/users/org/{0}/pagination`),
    removeSharedVisibilityByOrg: formatter(`${ORGANIZATION}/live-maps/org/{0}/visibility/remove`),
    removeSharedVisibilityByVehicle: formatter(`${ORGANIZATION}/live-maps/org/{0}/by-vehicle/visibility/remove`),
    getUniqueSharedVehicles: formatter(`${ORGANIZATION}/live-maps/org/{0}/unique-shared-vehicles`),
    getFilteredOrgsByVehicles: `${ORGANIZATION}/live-maps/shared-orgs-of-vehicles`,
    getUsersOfSelectedSharedOrg: formatter(`${ORGANIZATION}/live-maps/org/{0}/users-of-vehicles-by-org`),
    updateSmsTypes: formatter(`${ORGANIZATION}/workflow-configs/{0}/operations/{1}/update-sms-types}`),
    getSmsTypeStatus: formatter(`${ORGANIZATION}/workflow-configs/{0}/operations/{1}`),
  },
  loadManifests: {
    getLoadManifests: formatter(`${LOAD_MANIFESTS}/org/{0}/jobRefId/{1}/excel-file`),
  },
  apiManagement: {
    apis: `${ORGANIZATION}/apis`,
    apisById: formatter(`${ORGANIZATION}/apis/{0}`),
    resources: `${ORGANIZATION}/resources`,
    resourceById: formatter(`${ORGANIZATION}/resources/{0}`),
    resourcesByApi: formatter(`${ORGANIZATION}/resources/belongsToApi/{0}`),
    scopes: `${ORGANIZATION}/scopes`,
    scopeById: formatter(`${ORGANIZATION}/scopes/{0}`),
    findByBelongToApi: formatter(`${ORGANIZATION}/scopes/belongsToApi/{0}`),
    findScopesBySearch: formatter(`${ORGANIZATION}/scopes/search/{0}`)
  },
  apiGateway: {
    health: `${API_GATEWAY}/actuator/health`,
    restart: `${API_GATEWAY}/actuator/restart`
  },
  notification: {
    smsFindByType: formatter(`${NOTIFICATION}/sms-logs/find-by-type`),
    smsLogs: formatter(`${NOTIFICATION}/sms-logs`),
    smsLogsByFilter: formatter(`${NOTIFICATION}/sms-logs/count-by-date`),
  }
};

