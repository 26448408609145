<div class="change-currency col full-width-height">
  <div class="cc-header row">
    <p class="title">Convert Currency</p>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="cc-content col">
    <form [formGroup]="form" class="full-width">
      <mat-form-field class="full-width">
        <mat-label>Currency</mat-label>
        <mat-select formControlName="currency" (selectionChange)="onChangeCurrency($event.value)">
          <mat-option *ngFor="let currency of currencies" [value]="currency">
            {{currency}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Rate</mat-label>
        <input matInput formControlName="rate" type="number">
      </mat-form-field>
    </form>
  </div>
  <mat-divider></mat-divider>
  <div class="cc-footer row">
    <button mat-stroked-button color="warn" (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" (click)="onApplyCurrency()">Convert</button>
  </div>
</div>
